import * as React from 'react';
import { TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function RangePicker({ onSelectFilter, selectedFilter }) {
    const [startDate, setStartDate] = React.useState(dayjs(selectedFilter.startDate));
    const [endDate, setEndDate] = React.useState(dayjs(selectedFilter.endDate));

    React.useEffect(() => {
        setStartDate(dayjs(selectedFilter.startDate));
        setEndDate(dayjs(selectedFilter.endDate));
    }, [selectedFilter]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    value={startDate}
                    onChange={(e) => {
                        onSelectFilter({ ...selectedFilter, startDate: e.format('YYYY-MM-DD') });
                        setStartDate(e);
                    }}
                    renderInput={(props) => <TextField {...props} label="From" />}
                />
                <DatePicker
                    minDate={startDate}
                    value={endDate}
                    onChange={(e) => {
                        onSelectFilter({ ...selectedFilter, endDate: e.format('YYYY-MM-DD') });
                        setEndDate(e);
                    }} 
                    renderInput={(props) => <TextField {...props} label="To" />}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
