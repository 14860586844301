import axiosInstance from '../services';
import { useQuery } from 'react-query';

// generalized function for axios.get using axiosInstance
const fetchEmployessData = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// api endpoint calls
const fetchAllUsers = () => {
  const url = `/user`;
  return fetchEmployessData(url);
};

// hooks
export const useAllEmployess = () => {
  return useQuery(['allEmployess'], fetchAllUsers);
};