// import React, { useEffect, useState } from 'react';
// import { useGraphData } from '../context/GraphDataContext';
// import { GridLoader } from 'react-spinners';
// import Sidebar from './Sidebar';
// import SalesMatrix from './SalesMatrix';
// import '../styles/App.css';
// import '../styles/fonts.css';
// import SkeletonLoader from './SkeletonLoader';
// import NetworkError from './404';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const Forecast = () => {

//   const { graphData } = useGraphData();
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [markets, setMarkets] = useState([]);
//   const [projections, setProjections] = useState([]);
//   const [sliderDate, setSliderDate] = useState(new Date().toISOString().split('T')[0]);

//   const sliderTimestamp = new Date(sliderDate).getTime();

//   const marketNameMapping = {
//     'Tallmadge OH': 'Akron OH',
//     'Gonzales LA': 'Baton Rouge LA',
//     'Belton TX': 'Waco TX',
//     'Cape Girardeau MO': 'Marion IL',
//     'Conroe TX': 'Houston TX',
//     'Coralville IA': 'Cedar Rapids IA',
//     'Costa Mesa CA': 'Anaheim CA',
//     'Edison NJ July': 'Edison NJ',
//     'Edison NJ December': 'Edison NJ',
//     'Fort Lauderdale FL': 'Miami FL',
//     'Fort Worth TX': 'Ft. Worth TX',
//     'Franklin TN': 'Nashville TN',
//     'Fresno CA': 'Fesno CA',
//     'Harrisburg PA': 'York PA',
//     'Overland Park KS': 'Kansas City MO',
//     'Langhorne PA': 'Trenton NJ',
//     'Los Angeles': 'Los Angeles CA',
//     'Loveland CO': 'Fort Collins CO',
//     'Marlborough MA': 'Boston MA',
//     'Mason MI': 'Lansing MI',
//     'Nampa ID': 'Boise ID',
//     'North Charleston SC': 'Charleston SC',
//     'Owensboro KY': 'Evansville IN',
//     'Paducah KY': 'Marion IL',
//     'Philadelphia (Oaks) PA': 'Philadelphia PA',
//     'Pleasanton CA': 'Hayward CA',
//     'Puyallup WA': 'Tacoma WA',
//     'Raleigh NC NOV': 'Raleigh NC',
//     'Robstown TX': 'Corpus Christi TX',
//     'Rock Island / Quad Cities IL': 'Rock Island IL',
//     'Rogers AR': 'Fayetteville AR',
//     'Salina KA': 'Salina KS',
//     'Secaucus NJ': 'New York NY',
//     'St Charles MO': 'St. Louis MO',
//     'St Cloud MN': 'St. Cloud MN',
//     'Uncasville CT': 'Norwich CT',
//     'Ventura CA': 'Simi Valley CA',
//     'Winston-Salem NC': 'Greensboro NC',
//     'Novi MI': 'Detroit MI',
//     'Sandy UT': 'Salt Lake City UT',
//     'Belden MS': 'Tupelo MS',
//     'Garden City ID': 'Boise ID',
//     'Council Bluffs IA': 'Omaha NE',
//     'Conway AR': 'Little Rock AR',
//     'Kearney NE': 'Grand Island NE',
//     'Shawnee OK': 'Oklahoma City OK'
//   }

//   // helper function to consolidate the data when layering in projected numbers vs actual sales
//   function consolidateEventData(events) {
//     const consolidated = {};

//     events.forEach(event => {
//       const { EVENTID, DaysOut, DAILYTICKETCOUNT, Region, GeneralizedOpenDate, CalendarPosition } = event;
//       const CitySize = event['City Size'];
//       const ClusterMarket = event['Cluster Market']

//       if (!consolidated[EVENTID]) {
//         consolidated[EVENTID] = {
//           EVENTID,
//           CalendarPosition,
//           ClusterMarket,
//           GeneralizedOpenDate,
//           Region,
//           CitySize,
//           salesData: {},
//         };
//       }

//       consolidated[EVENTID].salesData[DaysOut] = DAILYTICKETCOUNT;
//     });

//     return Object.values(consolidated);
//   }

//   // function to calculate the latest medians of daily ticket sales by size, region, and calendar position
//   function calculateMediansByRegionAndSize(data) {
//     // const eventTotals = data.reduce((acc, event) => {
//     //   const eventId = event.EVENTID; 
//     //   acc[eventId] = (acc[eventId] || 0) + Math.round(event.DAILYTICKETCOUNT);
//     //   return acc;
//     // }, {});

//     const groupedData = {};
//     const eventIDsByGroup = {};
//     const undefinedMarkets = new Set();
//     const groupedDataRegionCitySize = {};

//     data.forEach(event => {
//       // if (eventTotals[event.EVENTID] < 1500) {
//       //   return;
//       // }

//       let sellerName = event.CorrectedSellerName;
//       if (marketNameMapping[sellerName]) {
//         sellerName = marketNameMapping[sellerName];
//       }

//       const groupKey = `${event.Region}_${event['City Size']}_${event.CalendarPosition}`;
//       const regionCitySizeKey = `${event.Region}_${event['City Size']}`;

//       if (!groupedData[groupKey]) {
//         groupedData[groupKey] = {};
//         eventIDsByGroup[groupKey] = {};
//       }
//       if (!groupedDataRegionCitySize[regionCitySizeKey]) {
//         groupedDataRegionCitySize[regionCitySizeKey] = {};
//       }

//       if (!groupedData[groupKey][event.DaysOut]) {
//         groupedData[groupKey][event.DaysOut] = [];
//         eventIDsByGroup[groupKey][event.DaysOut] = [];
//       }
//       if (!groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut]) {
//         groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut] = [];
//       }

//       groupedData[groupKey][event.DaysOut].push(Math.round(event.DAILYTICKETCOUNT));
//       eventIDsByGroup[groupKey][event.DaysOut].push(event.EVENTID);
//       groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut].push(Math.round(event.DAILYTICKETCOUNT));
//     });

//     // calculate medians for the detailed group (including CalendarPosition)
//     const mediansByGroup = {};
//     Object.keys(groupedData).forEach(group => {
//       mediansByGroup[group] = {};

//       Object.keys(groupedData[group]).forEach(daysOut => {
//         const values = groupedData[group][daysOut];
//         values.sort((a, b) => a - b);

//         const middleIndex = Math.floor(values.length / 2);
//         const median = values.length % 2 !== 0 ? values[middleIndex] : (values[middleIndex - 1] + values[middleIndex]) / 2;
//         mediansByGroup[group][daysOut] = {
//           median: Math.round(median),
//           eventIDs: eventIDsByGroup[group][daysOut]
//         }
//       });
//     });

//     // calculate medians for Region and City Size only
//     const mediansByRegionCitySize = {};
//     Object.keys(groupedDataRegionCitySize).forEach(group => {
//       mediansByRegionCitySize[group] = {};

//       Object.keys(groupedDataRegionCitySize[group]).forEach(daysOut => {
//         const values = groupedDataRegionCitySize[group][daysOut];
//         values.sort((a, b) => a - b);

//         const middleIndex = Math.floor(values.length / 2);
//         const median = values.length % 2 !== 0 ? values[middleIndex] : (values[middleIndex - 1] + values[middleIndex]) / 2;
//         mediansByRegionCitySize[group][daysOut] = Math.round(median);
//       });
//     });

//     console.log("Detailed Medians:", mediansByGroup);
//     console.log("Region & City Size Medians:", mediansByRegionCitySize);

//     return {
//       mediansByGroup: mediansByGroup,
//       mediansByRegionCitySize: mediansByRegionCitySize,
//       undefinedMarkets: Array.from(undefinedMarkets)
//     };
//   }

//   // layer in projections with actual sales data 
//   function generateProjections(events, mediansByGroup, mediansByRegionCitySize) {
//     return events.map(event => {
//       const { EVENTID, Region, CitySize, salesData, CalendarPosition } = event;
//       const detailedKey = `${Region}_${CitySize}_${CalendarPosition}`;
//       const generalKey = `${Region}_${CitySize}`;
//       let relevantMedians;

//       if (mediansByGroup[detailedKey]) {
//         relevantMedians = mediansByGroup[detailedKey];
//       } else if (mediansByRegionCitySize[generalKey]) {
//         relevantMedians = mediansByRegionCitySize[generalKey];
//       } else {
//         console.warn(`No median data for ${detailedKey} or ${generalKey}, skipping projection for ${EVENTID}`);
//         return event;
//       }

//       if (!relevantMedians) {
//         console.warn(`No median data for ${detailedKey} or ${generalKey}, skipping projection for ${EVENTID}`);
//         return event;
//       }

//       const projections = {};
//       const mostRecentDaysOut = Math.min(...Object.keys(salesData).map(Number));

//       for (let day = -2; day <= mostRecentDaysOut; day++) {
//         if (relevantMedians[day] !== undefined) {
//           projections[day] = typeof relevantMedians[day] === 'object' ? relevantMedians[day].median : relevantMedians[day];
//         }
//       }

//       return {
//         ...event,
//         projections
//       };
//     });
//   }

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get("https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/forecast-data");
//         let staticData = response.data;
//         console.log("forecast response ========>", response);
//         if (response.status === 200) {

//           setError(false)
//           setErrorMessage("")

//           const extractedMarkets = new Set(graphData.filter(row => row.EVENTID).map(row => row.EVENTID.split('_').slice(0, 2).join(', ')));
//           setMarkets([...extractedMarkets].sort());
          
//           // apply corrections to NormalizedSellerName before merging
//           const correctedGraphData = graphData.map(event => {
//             const correctedSellerName = event.SELLERNAME.replace(/\d{4}$/, '').trim();
//             return {
//               ...event,
//               CorrectedSellerName: marketNameMapping[correctedSellerName] || correctedSellerName
//             };
//           });
          
//           const normalizedStaticData = staticData.map(metric => {
//             return {
//               ...metric,
//               NormalizedClusterMarket: metric['Cluster Market'].replace(',', '').trim()
//             };
//           });

//           // merge using CorrectedSellerName
//           const mergedData = correctedGraphData.map(event => {
//             const matchingMetric = normalizedStaticData.find(metric => metric.NormalizedClusterMarket === event.CorrectedSellerName);
//             return { ...event, ...matchingMetric };
//           });

//           const consolidatedData = consolidateEventData(mergedData);
//           const { mediansByGroup } = calculateMediansByRegionAndSize(mergedData);
//           const projectionsData = generateProjections(consolidatedData, mediansByGroup);
//           setProjections(projectionsData);
//           console.log('Projections Data ===>', projectionsData)
//         }
//       } catch (error) {
//         setError(true)
//         setErrorMessage(error.message)
//         setIsLoading(false);
//         console.log("Error fetching API data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };
//     fetchData();
//   }, [graphData]);

//   const handleSliderChange = (e) => {
//     setSliderDate(e.target.value);
//   };

//   const { pathname } = useLocation()

//   return (
//     <div>
//       <div className="content-area">
//         <div className="date-filter">
//           <label htmlFor="date-slider">Events Starting After: </label>
//           <input
//             id="date-slider"
//             type="date"
//             value={sliderDate}
//             onChange={handleSliderChange}
//           />
//         </div>
//         {(isLoading) ? Array.from({ length: 5 }).map(() => <SkeletonLoader loading={true} width="100%" height={100} />) : error === true && errorMessage ? <NetworkError pathname={pathname} /> : <SalesMatrix projections={projections} sliderTimestamp={sliderTimestamp} />}
//       </div>
//     </div>
//   );
// }

// export default Forecast;


import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import SkeletonLoader from './SkeletonLoader';
import NetworkError from './404';
import SalesMatrix from './SalesMatrix';
import '../styles/App.css';
import '../styles/fonts.css';

// Fetch graph data with react-query
const fetchGraphData = async (url) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return typeof response.data === "string" ? JSON.parse(response.data) : response.data;
    }
    throw new Error('Failed to fetch graph data');
  } catch (error) {
    throw error;
  }
};

// Fetch forecast data separately
const fetchForecastData = async (url) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch forecast data');
  } catch (error) {
    throw error;
  }
};

const Forecast = () => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projections, setProjections] = useState([]);
  const [sliderDate, setSliderDate] = useState(new Date().toISOString().split('T')[0]);
  const sliderTimestamp = new Date(sliderDate).getTime();

  const { data: graphData = [], isLoading: isGraphDataLoading, error: graphDataError } = useQuery(
    'graphData',
    () => fetchGraphData('https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/graph-data')
  );

  const { data: forecastData, isLoading: isForecastDataLoading, error: forecastDataError } = useQuery(
    'forecastData',
    () => fetchForecastData('https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/forecast-data')
  );

  useEffect(() => {
    if (!isGraphDataLoading && !isForecastDataLoading) {
      try {
        if (graphDataError || forecastDataError) {
          throw new Error(graphDataError?.message || forecastDataError?.message || 'An error occurred');
        }

        const marketNameMapping = {
          'Tallmadge OH': 'Akron OH',
          'Gonzales LA': 'Baton Rouge LA',
          'Belton TX': 'Waco TX',
          'Cape Girardeau MO': 'Marion IL',
          'Conroe TX': 'Houston TX',
          'Coralville IA': 'Cedar Rapids IA',
          'Costa Mesa CA': 'Anaheim CA',
          'Edison NJ July': 'Edison NJ',
          'Edison NJ December': 'Edison NJ',
          'Fort Lauderdale FL': 'Miami FL',
          'Fort Worth TX': 'Ft. Worth TX',
          'Franklin TN': 'Nashville TN',
          'Fresno CA': 'Fresno CA',
          'Harrisburg PA': 'York PA',
          'Overland Park KS': 'Kansas City MO',
          'Langhorne PA': 'Trenton NJ',
          'Los Angeles': 'Los Angeles CA',
          'Loveland CO': 'Fort Collins CO',
          'Marlborough MA': 'Boston MA',
          'Mason MI': 'Lansing MI',
          'Nampa ID': 'Boise ID',
          'North Charleston SC': 'Charleston SC',
          'Owensboro KY': 'Evansville IN',
          'Paducah KY': 'Marion IL',
          'Philadelphia (Oaks) PA': 'Philadelphia PA',
          'Pleasanton CA': 'Hayward CA',
          'Puyallup WA': 'Tacoma WA',
          'Raleigh NC NOV': 'Raleigh NC',
          'Robstown TX': 'Corpus Christi TX',
          'Rock Island / Quad Cities IL': 'Rock Island IL',
          'Rogers AR': 'Fayetteville AR',
          'Salina KA': 'Salina KS',
          'Secaucus NJ': 'New York NY',
          'St Charles MO': 'St. Louis MO',
          'St Cloud MN': 'St. Cloud MN',
          'Uncasville CT': 'Norwich CT',
          'Ventura CA': 'Simi Valley CA',
          'Winston-Salem NC': 'Greensboro NC',
          'Novi MI': 'Detroit MI',
          'Sandy UT': 'Salt Lake City UT',
          'Belden MS': 'Tupelo MS',
          'Garden City ID': 'Boise ID',
          'Council Bluffs IA': 'Omaha NE',
          'Conway AR': 'Little Rock AR',
          'Kearney NE': 'Grand Island NE',
          'Shawnee OK': 'Oklahoma City OK'
        };

        function consolidateEventData(events) {
          const consolidated = {};

          events.forEach(event => {
            const { EVENTID, DaysOut, DAILYTICKETCOUNT, Region, GeneralizedOpenDate, CalendarPosition } = event;
            const CitySize = event['City Size'];
            const ClusterMarket = event['Cluster Market'];

            if (!consolidated[EVENTID]) {
              consolidated[EVENTID] = {
                EVENTID,
                CalendarPosition,
                ClusterMarket,
                GeneralizedOpenDate,
                Region,
                CitySize,
                salesData: {},
              };
            }

            consolidated[EVENTID].salesData[DaysOut] = DAILYTICKETCOUNT;
          });

          return Object.values(consolidated);
        }

        function calculateMediansByRegionAndSize(data) {
          const groupedData = {};
          const eventIDsByGroup = {};
          const groupedDataRegionCitySize = {};

          data.forEach(event => {
            let sellerName = event.CorrectedSellerName;
            if (marketNameMapping[sellerName]) {
              sellerName = marketNameMapping[sellerName];
            }

            const groupKey = `${event.Region}_${event['City Size']}_${event.CalendarPosition}`;
            const regionCitySizeKey = `${event.Region}_${event['City Size']}`;

            if (!groupedData[groupKey]) {
              groupedData[groupKey] = {};
              eventIDsByGroup[groupKey] = {};
            }
            if (!groupedDataRegionCitySize[regionCitySizeKey]) {
              groupedDataRegionCitySize[regionCitySizeKey] = {};
            }

            if (!groupedData[groupKey][event.DaysOut]) {
              groupedData[groupKey][event.DaysOut] = [];
              eventIDsByGroup[groupKey][event.DaysOut] = [];
            }
            if (!groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut]) {
              groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut] = [];
            }

            groupedData[groupKey][event.DaysOut].push(Math.round(event.DAILYTICKETCOUNT));
            eventIDsByGroup[groupKey][event.DaysOut].push(event.EVENTID);
            groupedDataRegionCitySize[regionCitySizeKey][event.DaysOut].push(Math.round(event.DAILYTICKETCOUNT));
          });

          const mediansByGroup = {};
          Object.keys(groupedData).forEach(group => {
            mediansByGroup[group] = {};

            Object.keys(groupedData[group]).forEach(daysOut => {
              const values = groupedData[group][daysOut];
              values.sort((a, b) => a - b);

              const middleIndex = Math.floor(values.length / 2);
              const median = values.length % 2 !== 0 ? values[middleIndex] : (values[middleIndex - 1] + values[middleIndex]) / 2;
              mediansByGroup[group][daysOut] = {
                median: Math.round(median),
                eventIDs: eventIDsByGroup[group][daysOut]
              }
            });
          });

          const mediansByRegionCitySize = {};
          Object.keys(groupedDataRegionCitySize).forEach(group => {
            mediansByRegionCitySize[group] = {};

            Object.keys(groupedDataRegionCitySize[group]).forEach(daysOut => {
              const values = groupedDataRegionCitySize[group][daysOut];
              values.sort((a, b) => a - b);

              const middleIndex = Math.floor(values.length / 2);
              const median = values.length % 2 !== 0 ? values[middleIndex] : (values[middleIndex - 1] + values[middleIndex]) / 2;
              mediansByRegionCitySize[group][daysOut] = Math.round(median);
            });
          });

          return {
            mediansByGroup,
            mediansByRegionCitySize
          };
        }

        function generateProjections(events, mediansByGroup, mediansByRegionCitySize) {
          return events.map(event => {
            const { EVENTID, Region, CitySize, salesData, CalendarPosition } = event;
            const detailedKey = `${Region}_${CitySize}_${CalendarPosition}`;
            const generalKey = `${Region}_${CitySize}`;
            let relevantMedians;

            if (mediansByGroup[detailedKey]) {
              relevantMedians = mediansByGroup[detailedKey];
            } else if (mediansByRegionCitySize[generalKey]) {
              relevantMedians = mediansByRegionCitySize[generalKey];
            } else {
              console.warn(`No median data for ${detailedKey} or ${generalKey}, skipping projection for ${EVENTID}`);
              return event;
            }

            const projections = {};
            const mostRecentDaysOut = Math.min(...Object.keys(salesData).map(Number));

            for (let day = -2; day <= mostRecentDaysOut; day++) {
              if (relevantMedians[day] !== undefined) {
                projections[day] = typeof relevantMedians[day] === 'object' ? relevantMedians[day].median : relevantMedians[day];
              }
            }

            return {
              ...event,
              projections
            };
          });
        }

        const correctedGraphData = graphData.map(event => {
          const correctedSellerName = event.SELLERNAME.replace(/\d{4}$/, '').trim();
          return {
            ...event,
            CorrectedSellerName: marketNameMapping[correctedSellerName] || correctedSellerName
          };
        });

        const normalizedStaticData = forecastData.map(metric => ({
          ...metric,
          NormalizedClusterMarket: metric['Cluster Market'].replace(',', '').trim()
        }));

        const mergedData = correctedGraphData.map(event => {
          const matchingMetric = normalizedStaticData.find(metric => metric.NormalizedClusterMarket === event.CorrectedSellerName);
          return { ...event, ...matchingMetric };
        });

        const consolidatedData = consolidateEventData(mergedData);
        const { mediansByGroup, mediansByRegionCitySize } = calculateMediansByRegionAndSize(mergedData);
        const projectionsData = generateProjections(consolidatedData, mediansByGroup, mediansByRegionCitySize);
        setProjections(projectionsData);
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    }
  }, [graphData, forecastData, isGraphDataLoading, isForecastDataLoading, graphDataError, forecastDataError]);

  const handleSliderChange = (e) => {
    setSliderDate(e.target.value);
  };

  return (
    <div>
      <div className="content-area">
        <div className="date-filter">
          <label htmlFor="date-slider">Events Starting After: </label>
          <input
            id="date-slider"
            type="date"
            value={sliderDate}
            onChange={handleSliderChange}
          />
        </div>
        {(isGraphDataLoading || isForecastDataLoading) ? (
          Array.from({ length: 5 }).map((_, index) => <SkeletonLoader key={index} loading={true} width="100%" height={100} />)
        ) : error ? (
          <NetworkError message={errorMessage} />
        ) : (
          <SalesMatrix projections={projections} sliderTimestamp={sliderTimestamp} />
        )}
      </div>
    </div>
  );
}

export default Forecast;
