/**
 * @file Login.js
 * 
 * @description
 * provides a user interface for authentication, allowing users to log in to the application.
 * this component handles user credentials and communicates with the auth context to manage user sessions.
 * 
 * @dependencies
 * - **useAuth**: hook from `AuthContext` used for authentication processes.
 * 
 * @state_management
 * - **username**: stores the username input by the user.
 * - **password**: stores the password input by the user.
 * 
 * @functionality
 * - **handleSubmit**: manages the form submission for logging in. It calls the `login` method from the auth context
 *   with the username, password, and a callback to handle success or failure.
 * 
 * @custom_components
 * - **BackgroundCircle**: renders a decorative SVG background with two colored circles using linear gradients.
 * 
 * @usage
 * <Login />
 * acts as the entry point for users to access the main parts of the application. It is typically the first component
 * a user interacts with upon visiting the application.
 */

import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';
import { HandleLogin, HandleSignup } from '../middlewares/auth';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import { GridLoader } from 'react-spinners';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Form from './UI-components/Form/form';

const Signup = () => {

    const { formFields, setFormFields, setError, setSuccess, loading, setLoading, validationError, setValidationError, emailRegex } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (emailRegex.test(formFields.email)) {
            setValidationError("")
            setLoading(true)
            HandleSignup(formFields)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        setLoading(false)
                        setSuccess(true)
                        setError(false)
                        enqueueSnackbar("User registered successfully", {
                            variant: "success",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }
                        })
                        setTimeout(() => {
                            navigate("/login")
                        }, 2000);
                    }
                    else {
                        console.log(res);
                        setLoading(false)
                        setSuccess(false)
                        setError(true)
                        enqueueSnackbar(res.response.data.message, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false)
                    setSuccess(false)
                    setError(true)
                })
        }
        else {
            console.log("please enter valid email");
            setValidationError("please enter valid email")
            enqueueSnackbar("please enter valid email", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }
            })
        }
    };

    const BackgroundCircle = () => {
        return (
            <svg className="svg-background" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: '#3C50E0', stopOpacity: 0.8 }} />
                        <stop offset="100%" style={{ stopColor: '#8e44ad', stopOpacity: 0.8 }} />
                    </linearGradient>
                    <linearGradient id="grad2" x1="0%" y1="100%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#3C50E0', stopOpacity: 0.8 }} />
                        <stop offset="100%" style={{ stopColor: '#8e44ad', stopOpacity: 0.8 }} />
                    </linearGradient>
                </defs>
                <circle cx="30" cy="30" r="14" fill="url(#grad1)" />
                <circle cx="70" cy="70" r="20" fill="url(#grad2)" />
            </svg>
        );
    };

    console.log(formFields);

    const goToLogin = () => {
        navigate("/login")
        setValidationError("")
        setFormFields((prevFormFields) => {
            return {
                ...prevFormFields,
                first_name: "",
                last_name: "",
                email: ""
            }
        })
    }

    return (
        <div className="login-container">
            <SnackbarProvider />
            <BackgroundCircle />
            <div className="login-card">
                <h2>FQE Analytics</h2>
                {loading ? (
                    <div style={{ margin: "100px 0" }}>
                        <GridLoader color={'#3C50E0'} loading={true} size={25} />
                    </div>
                ) : (
                    <Form
                        type="signup"
                        handleSubmit={handleSubmit}
                        validationError={validationError}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        switchForm={goToLogin}
                    />
                )}
            </div>
        </div>
    );
};

export default Signup;
