/**
 * @file MarketDataBox.js
 * 
 * @description
 * displays key market data metrics in a compact, easy-to-read box format. this component is used to visualize
 * specific market-related information such as market classification, digital audience size, median income, and
 * forecasted ticket sales potential.
 * 
 * @props
 * - **data**: array of market data objects, where each object contains market-related metrics.
 * 
 * @functionality
 * - **baselineForecast**: calculates the average of the 'Upside Forecast (Tickets)' and 'Downside Forecast (Tickets)'
 *   to provide a steady state potential estimate for ticket sales.
 * 
 * @usage
 * <MarketDataBox />
 * used within market-related views or dashboards to provide a quick statistical overview of individual markets,
 * enhancing data visualization and making critical metrics immediately accessible and understandable.
 * 
 * @conditional_rendering
 * displays a loading message if data is not yet available or if the data array is empty, ensuring that users are informed
 * about the state of data fetching.
 */

import React, { useState, useEffect } from 'react';
import "../styles/DataBox.css";

const MarketDataBox = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>Loading market data...</div>;
  }

  const baselineForecast = (upside, downside) => {
    return (Number(upside) + Number(downside)) / 2
  }

  return (
    <div className="box-container">
      <div className="box-section">
        <div className="metric-row">
          <span className="main-metric">{data[0]['Market Rank (Indoor)']}</span>
        </div>
        <div className="label">Market Classification</div>
      </div>
      <div className="box-section">
        <div className="metric-row">
          <span className="main-metric">{data[0]['Digital Audience Size (20 Mile)'].toLocaleString()}</span>
        </div>
        <div className="label">Digital Audience Size</div>
      </div>
      <div className="box-section">
        <div className="metric-row">
          <span className="main-metric">${data[0]['Median Income ($)'].toLocaleString()}</span>
        </div>
        <div className="label">Median Household Income</div>
      </div>
      <div className="box-section">
        <div className="metric-row">
          <span className="main-metric">{baselineForecast(data[0]['Upside Forecast (Tickets)'], data[0]['Downside Forecast (Tickets)']).toLocaleString()}</span>
        </div>
        <div className="label">Steady State Potential</div>
        <div className="label">(Ticket Sales)</div>
      </div>
    </div>
  );
};

export default MarketDataBox;
