import { createSlice } from '@reduxjs/toolkit';
import {
    fetchJQCompetition,
} from '../services/competition'; // Assuming these thunks are exported from a separate file

const initialState = {
    competitions: [],
    loading: false,
    error: null,
};

const competitionSlice = createSlice({
    name: 'ticketure',
    initialState,
    reducers: {
        setCompetitions: (state, { payload }) => {
            state.competitions = payload
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJQCompetition.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJQCompetition.fulfilled, (state, action) => {
                state.loading = false;
                state.competitions = action.payload.data;
            })
            .addCase(fetchJQCompetition.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export const { setCompetitions, setEditFields, setLoading } = competitionSlice.actions
export default competitionSlice.reducer;