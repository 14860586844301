import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import MarketingDataBox from './MarketingDataBox';
import MarketingGraph from './MarketingGraph';
import SkeletonLoader from './SkeletonLoader';
import MetricToggle from './MetricToggle';
import NetworkError from './404';
import { useLocation } from 'react-router-dom';
import RangePicker from './DateFilter';
import { useMetaInsightByDate } from '../middlewares/metaInsight';
import { fetchMetaInsightByDate, fetchMetaInsightByDate2 } from '../services/ticketure';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

const columns = [
  { label: 'Date', dataKey: 'date', numeric: false, minWidth: 30, maxWidth: 30 },
  { label: 'Spend ($)', dataKey: 'spend', numeric: true, minWidth: 30, maxWidth: 30 },
  { label: 'Impressions', dataKey: 'impressions', numeric: true, width: 120 },
  { label: 'Post Engagement', dataKey: 'inline_post_engagement', numeric: true, width: 120 },
  { label: 'Link Clicks', dataKey: 'inline_link_clicks', numeric: true, width: 120 },
  { label: 'CPM ($)', dataKey: 'CPM', numeric: true, width: 120 },
  { label: 'Number of Markets', dataKey: 'number_of_markets', numeric: true, width: 120 }
];

const metricDisplayNames = {
  spend: 'Spend',
  impressions: 'Impressions',
  inline_post_engagement: 'Post Engagement',
  inline_link_clicks: 'Link Clicks'
};

const calculateDateRanges = (filter) => {
  const { startDate, endDate } = filter
  const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
  const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');
  const lastYearStartDate = format(new Date(new Date(formattedStartDate).setFullYear(new Date(formattedStartDate).getFullYear() - 1)), 'yyyy-MM-dd');
  const lastYearEndDate = format(new Date(new Date(formattedEndDate).setFullYear(new Date(formattedEndDate).getFullYear() - 1)), 'yyyy-MM-dd');
  return { startDate: formattedStartDate, endDate: formattedEndDate, lastYearStartDate, lastYearEndDate };
};

const calculateMetrics = (data) => {
  const totalSpend = data?.reduce((acc, item) => acc + item.spend, 0);
  const inline_link_clicks = data?.reduce((acc, item) => acc + item.inline_link_clicks, 0);
  const inline_post_engagement = data?.reduce((acc, item) => acc + item.inline_post_engagement, 0);
  const impressions = data?.reduce((acc, item) => acc + item.impressions, 0);

  return [
    { id: 1, title: "Spend", value: totalSpend, formattedValue: formatNumber(totalSpend, true), percent: '-' },
    { id: 2, title: "Link Clicks", value: inline_link_clicks, formattedValue: formatNumber(inline_link_clicks), percent: '-' },
    { id: 3, title: "Post Engagement", value: inline_post_engagement, formattedValue: formatNumber(inline_post_engagement), percent: '-' },
    { id: 4, title: "Impressions", value: impressions, formattedValue: formatNumber(impressions), percent: '-' },
  ];
};

const calculateAdditionalMetrics = (data) => {
  const totalSpend = data?.reduce((acc, item) => acc + item.spend, 0);
  const inline_link_clicks = data?.reduce((acc, item) => acc + item.inline_link_clicks, 0);
  const inline_post_engagement = data?.reduce((acc, item) => acc + item.inline_post_engagement, 0);
  const impressions = data?.reduce((acc, item) => acc + item.impressions, 0);

  const cpc = inline_link_clicks ? totalSpend / inline_link_clicks : 0;
  const cpm = impressions ? (totalSpend / impressions) * 1000 : 0;
  const cpe = inline_post_engagement ? totalSpend / inline_post_engagement : 0;
  const ctr = impressions ? (inline_link_clicks / impressions) * 100 : 0;

  return [
    { id: 1, title: "CPC", value: cpc, formattedValue: formatNumber(cpc, true), percent: '-' },
    { id: 2, title: "CPM", value: cpm, formattedValue: formatNumber(cpm, true), percent: '-' },
    { id: 3, title: "CPE", value: cpe, formattedValue: formatNumber(cpe, true), percent: '-' },
    { id: 4, title: "CTR", value: ctr, formattedValue: ctr.toFixed(2) + '%', percent: '-' },
  ];
};

const formatNumber = (value, isCurrency = false) => {
  if (value == null) return isCurrency ? '$0.00' : '0';
  return isCurrency
    ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })
    : value.toLocaleString('en-US');
};

const useGroupedMarketingData = (marketingInsightsData, lastYearMarketingInsightsData) => {

  return useMemo(() => {
    const initializeMetricObject = () => ({
      spend: 0,
      impressions: 0,
      inline_post_engagement: 0,
      inline_link_clicks: 0,
      // ticket_sales: 0
    });

    const groupDataByDate = (data) => data?.reduce((acc, currentValue) => {
      const date = new Date(currentValue.date_start);
      const utcDate = `${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(date.getUTCDate()).padStart(2, '0')}/${date.getUTCFullYear()}`;

      if (!acc[utcDate]) {
        acc[utcDate] = {
          date: utcDate,
          ...initializeMetricObject(),
          markets: new Set(),
          details: {},
        };
      }

      Object.keys(initializeMetricObject()).forEach(metric => {
        acc[utcDate][metric] += currentValue[metric] || 0;
      });

      acc[utcDate].markets.add(currentValue.market_name);

      if (!acc[utcDate].details[currentValue.market_name]) {
        acc[utcDate].details[currentValue.market_name] = {
          market: currentValue.market_name,
          ...initializeMetricObject(),
        };
      }

      Object.keys(initializeMetricObject()).forEach(metric => {
        acc[utcDate].details[currentValue.market_name][metric] += currentValue[metric] || 0;
      });

      return acc;
    }, {});

    const groupedByDate = groupDataByDate(marketingInsightsData);
    const groupedByDateLastYear = groupDataByDate(lastYearMarketingInsightsData);

    const combinedData = Object.keys(groupedByDate).map(date => {
      const lastYearDate = `${date.split('/')[0]}/${date.split('/')[1]}/${parseInt(date.split('/')[2]) - 1}`;

      // const ticketSales = graphData
      //   .filter(sale => {
      //     const saleDate = new Date(sale.SALEDATE);
      //     const newSaleDate = `${String(saleDate.getUTCMonth() + 1).padStart(2, '0')}/${String(saleDate.getUTCDate()).padStart(2, '0')}/${saleDate.getUTCFullYear()}`;
      //     return newSaleDate === date;
      //   })
      //   .reduce((acc, sale) => acc + (sale.DAILYTICKETCOUNT || 0), 0); 

      // const lastYearTicketSales = graphData
      //   .filter(sale => {
      //     const saleDate = new Date(sale.SALEDATE);
      //     const newSaleDate = `${String(saleDate.getUTCMonth() + 1).padStart(2, '0')}/${String(saleDate.getUTCDate()).padStart(2, '0')}/${saleDate.getUTCFullYear()}`;
      //     return newSaleDate === lastYearDate;
      //   })
      //   .reduce((acc, sale) => acc + (sale.DAILYTICKETCOUNT || 0), 0);

      return {
        date,
        ...groupedByDate[date],
        lastYearData: {
          ...groupedByDateLastYear[lastYearDate] || initializeMetricObject(),
          // ticket_sales: lastYearTicketSales
        },
        // ticket_sales: ticketSales, 
        CPM: groupedByDate[date].impressions > 0 ? (groupedByDate[date].spend / groupedByDate[date].impressions) * 1000 : 0,
        number_of_markets: groupedByDate[date].markets.size,
        details: Object.values(groupedByDate[date].details),
      };
    }).sort((a, b) => new Date(a.date) - new Date(b.date));

    return combinedData;

  }, [marketingInsightsData, lastYearMarketingInsightsData]);
};

function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ minWidth: 30, maxWidth: 30, padding: '0 16px' }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.date}</TableCell>
        {columns.slice(1).map(column => (
          <TableCell key={column.dataKey} align="right">
            {formatNumber(row[column.dataKey], column.dataKey === 'spend' || column.dataKey === 'cpm')}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Market Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Market</TableCell>
                    <TableCell align="right">Spend</TableCell>
                    <TableCell align="right">Impressions</TableCell>
                    <TableCell align="right">Post Engagement</TableCell>
                    <TableCell align="right">Inline Link Clicks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detail) => (
                    <TableRow key={detail.market}>
                      <TableCell component="th" scope="row">{detail.market}</TableCell>
                      <TableCell align="right">{formatNumber(detail.spend, true)}</TableCell>
                      <TableCell align="right">{formatNumber(detail.impressions)}</TableCell>
                      <TableCell align="right">{formatNumber(detail.inline_post_engagement)}</TableCell>
                      <TableCell align="right">{formatNumber(detail.inline_link_clicks)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Component() {

  const companyName = useSelector((state) => state.company.company)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [marketBoxData, setMarketingBoxData] = useState([]);
  const [marketBoxCalculatedData, setMarketingBoxCalculatedData] = useState([]);
  const [marketingInsightsData, setMarketingInsightsData] = useState([]);
  const [lastYearMarketingInsightsData, setLastYearMarketingInsightsData] = useState([]);
  const [filter, setFilter] = useState({ startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), endDate: new Date() });
  const [selectedMetric, setSelectedMetric] = useState('spend');

  const dispatch = useDispatch()
  const currentData = useSelector((state) => state.ticketure.metaInsight1)
  const lastYearData = useSelector((state) => state.ticketure.metaInsight2)
  const loading = useSelector((state) => state.ticketure.loading)

  const rows = useGroupedMarketingData(marketingInsightsData, lastYearMarketingInsightsData);
  const chartLabels = rows.map(row => row.date);
  const currentMetricData = rows.map(row => row[selectedMetric].toFixed(0));
  const lastYearMetricData = rows.map(row => row.lastYearData[selectedMetric]);

  const { startDate, endDate, lastYearStartDate, lastYearEndDate } = useMemo(() => calculateDateRanges(filter), [filter]);

  const chartData = [
    {
      name: `Current ${metricDisplayNames[selectedMetric]}`,
      data: currentMetricData,
      color: '#60a3bc',
    },
    {
      name: `Previous Year ${metricDisplayNames[selectedMetric]}`,
      data: lastYearMetricData,
      color: '#fad390',
    },
  ];

  useEffect(() => {
    if (currentData?.length > 0 && lastYearData?.length > 0) {
      // if (currentData?.message === "Network Error") {
      //   setError(true)
      //   setErrorMessage("NETWORK ERROR")
      //   setIsMarketingInsightsDataLoading(false);
      // }
      // else {
      setError(false)
      setErrorMessage("")
      setMarketingInsightsData(currentData);
      setLastYearMarketingInsightsData(lastYearData);
      const currentMetrics = calculateMetrics(currentData);
      const additionalCurrentMetrics = calculateAdditionalMetrics(currentData);
      const lastYearMetrics = calculateMetrics(lastYearData);
      const additionalLastYearMetrics = calculateAdditionalMetrics(lastYearData);
      const combinedMetrics = currentMetrics.map((metric, index) => {
        const lastYearValue = lastYearMetrics[index]?.value || 0;
        const percent = lastYearValue > 0 ? ((metric.value - lastYearValue) / lastYearValue * 100).toFixed(2) : '-';
        return {
          ...metric,
          percent,
          lastYearValue: lastYearMetrics[index]?.formattedValue || '0',
        };
      });
      const combinedAdditionalMetrics = additionalCurrentMetrics.map((metric, index) => {
        const lastYearValue = additionalLastYearMetrics[index]?.value || 0;
        const percent = lastYearValue > 0 ? ((metric.value - lastYearValue) / lastYearValue * 100).toFixed(2) : '-';
        return {
          ...metric,
          percent,
          lastYearValue: additionalLastYearMetrics[index]?.formattedValue || '0',
        };
      });
      setMarketingBoxData(combinedMetrics);
      setMarketingBoxCalculatedData(combinedAdditionalMetrics);
      // }
    }
  }, [currentData, lastYearData]);

  useEffect(() => {
    dispatch(fetchMetaInsightByDate({ startDate, endDate }))
  }, [startDate, endDate, companyName])

  useEffect(() => {
    dispatch(fetchMetaInsightByDate2({ lastYearStartDate, lastYearEndDate }))
  }, [lastYearStartDate, lastYearEndDate, companyName])

  return (
    <Paper style={{ padding: '30px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", marginBottom: '30px' }}>
        {/* <MarketingFilter onSelectFilter={setFilter} selectedFilter={filter} /> */}
        <RangePicker onSelectFilter={setFilter} selectedFilter={filter} />
        {/* <MarketIds /> */}
        <MetricToggle selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} />
      </div>
      <Grid container spacing={3} style={{ width: '100%' }}>
        {(loading ? Array.from({ length: 4 }) : marketBoxData).map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            {loading ? <SkeletonLoader loading={loading} width="100%" height={100} /> : <MarketingDataBox market={item} />}
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading ? <SkeletonLoader loading={loading} width="100%" height={475.01} /> : <MarketingGraph chartLabels={chartLabels} chartData={chartData} selectedFilter={filter} selectedMetric={selectedMetric} />}
        </Grid>
        {(loading ? Array.from({ length: 4 }) : marketBoxCalculatedData).map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            {loading ? <SkeletonLoader loading={loading} width="100%" height={100} /> : <MarketingDataBox market={item} />}
          </Grid>
        ))}
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} style={{ width: `${column.widthPercentage}%` }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={columns.length + 1}>
                    <SkeletonLoader loading={loading} height={66.81} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              rows?.map((row, index) => (
                <Row key={index} row={row} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function CollapsibleTable() {
  const error = false;
  const errorMessage = "";
  const { pathname } = useLocation()
  return error === true && errorMessage ? <NetworkError pathname={pathname} /> : <Component />
}

export default CollapsibleTable;