import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, CircularProgress, Grid, MenuItem, Select, TextField } from '@mui/material';
import ModalComp from '../Modal/modal';
import dayjs from 'dayjs';

function DataTable(props) {

  const {
    data,
    columns,
    handleUpdateSettings,
    formFields,
    handleChange,
    showEditModal,
    setShowEditModal,
    editFields,
    setEditFields,
    loading,
    categories
  } = props

  return (
    <div style={{ height: "70vh", width: '100%', overflowX: "auto", position: "relative", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "20px" }}>
      <DataGrid
        rows={[...data].sort((a, b) => b.id - a.id)}
        columns={columns}
        pageSize={5}
        style={{ width: "100%", minWidth: '600px', position: "absolute" }}
      />

      {showEditModal && (
        <ModalComp open={showEditModal} handleClose={() => setShowEditModal(false)}>
          <Grid container spacing={2}>
            {Object.entries(formFields).map(([k, v]) => (
              <React.Fragment key={k}>
                {k === "opening_date" || k === "closing_date" ? (
                  <Grid item xs={12} sm={6}>
                    <div className='my-date-picker'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                             value={v ? dayjs(v) : null}
                             onChange={(e) => {
                              const formattedDate = dayjs(e).format('YYYY-MM-DD');
                              handleChange(k, formattedDate);
                              setEditFields((prevEditFields) => ({
                                ...prevEditFields,
                                [k]: formattedDate
                              }));
                            }}
                            label={k.replace("_", " ").toUpperCase()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Grid>
                ) : k === "category" ? (
                  <Grid item xs={12} sm={12} md={12} key={k}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={v}
                      label="Select Category"
                      onChange={(e) => handleChange(k, e.target.value)}
                      sx={{ width: "100%" }}
                    >
                      {categories.map((cat) => <MenuItem value={cat}>{cat}</MenuItem>)}
                    </Select>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => {
                        handleChange(k, e.target.value)
                        setEditFields((prevEditFields) => {
                          return {
                            ...prevEditFields,
                            [k]: e.target.value
                          }
                        })
                      }}
                      value={v}
                      sx={{ margin: "10px 0", textTransform: "capitalize" }}
                      fullWidth
                      id="outlined-basic"
                      label={k.replace("_", " ").toUpperCase()}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <div style={{ textAlign: "right" }}>
            <Button disabled={Object.keys(editFields).length === 0 || loading} style={{ width: "200px", fontWeight: "bold" }} variant="contained" onClick={handleUpdateSettings}>{loading ? <CircularProgress size={30} /> : "Update"}</Button>
          </div>
        </ModalComp>
      )}
    </div>

  );
}

export default DataTable;