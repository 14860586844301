// ignore this file for now. we'll come back to it.

import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Circle, Marker, LayersControl, LayerGroup, Popup } from 'react-leaflet';
import { Polyline } from 'react-leaflet';
import L from 'leaflet';
import Papa from 'papaparse';
import 'leaflet.motion/dist/leaflet.motion.js';
import 'leaflet/dist/leaflet.css';

const Legend = () => {
  return (
    <div style={{
      position: 'absolute',
      bottom: '10px',
      left: '300px',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '5px',
      zIndex: 500
    }}>
        <div><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9s-1.12 2.5-2.5 2.5z" fill="#574b90"/></g></svg><span>Tour 1</span></div>
        <div><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9s-1.12 2.5-2.5 2.5z" fill="#f19066"/></g></svg><span>Tour 2</span></div>
        <div><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9s-1.12 2.5-2.5 2.5z" fill="#22a6b3"/></g></svg><span>Tour 3</span></div>
        <br />
        <div><span style={{ backgroundColor: '#2ecc71', width: '15px', height: '15px', display: 'inline-block', borderRadius: '50%'}}></span> A Market</div>
        <div><span style={{ backgroundColor: '#3498db', width: '15px', height: '15px', display: 'inline-block', borderRadius: '50%' }}></span> B Market</div>
        <div><span style={{ backgroundColor: '#f1c40f', width: '15px', height: '15px', display: 'inline-block', borderRadius: '50%' }}></span> C Market</div>
        <div><span style={{ backgroundColor: '#e74c3c', width: '15px', height: '15px', display: 'inline-block', borderRadius: '50%' }}></span> D Market</div>
    </div>
  )
}

const MainMap = () => {
  const [marketData, setMarketData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(new Date('2023-12-30'));
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString()); // Default to current year as string

  const parseYearFromDateString = (dateString) => {
    const datePart = dateString.split(',')[1].trim(); // Assuming the date is in a specific format
    const [month, day, year] = datePart.split('/').map(Number);
    return year + 2000; // Adjust based on how your years are stored (e.g., '24' becomes 2024)
  };  

  const tourColors = {
    '1': '574b90',  
    '2': 'f19066',  
    '3': '22a6b3'   
  };
  
  const createCustomIcon = (color) => {
    return new L.Icon({
      iconUrl: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9s-1.12 2.5-2.5 2.5z' fill='%23${color}'/%3E%3C/svg%3E`,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    })
  };

  useEffect(() => {
    let today = new Date();
    let dayOfWeek = today.getDay();
    let daysToLastFriday = dayOfWeek === 5 ? 0 : (dayOfWeek + 2) % 7;
    let lastFriday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysToLastFriday);
    setCurrentWeek(lastFriday);
  }, []); 

  useEffect(() => {
    const fetchCSVData = async () => {
      const fetchAndParseCSV = async (csvFilePath, filterCondition) => {
        const response = await fetch(csvFilePath);
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value);
        return new Promise((resolve, reject) => {
          Papa.parse(csv, {
            header: true,
            complete: (results) => {
              const filteredResults = results.data.filter(filterCondition);
              resolve(filteredResults);
            },
            error: (error) => reject(error)
          });
        });
      };
    
      try {
        const allClustersData = await fetchAndParseCSV('all_cluster.csv', row => row.location);
        const scheduleData = await fetchAndParseCSV('schedule.csv', row => row.location && row.date);
        setMarketData(allClustersData);
        setTourData(scheduleData);
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      }
    };

    fetchCSVData();
  }, [currentWeek]);

  const parseDateString = (dateString) => {
    const datePart = dateString.split(',')[1].trim();
    const [month, day, year] = datePart.split('/').map(Number);
    const newDate = new Date(year + 2000, month - 1, day);
    return newDate;
  };

  const formatWeekDisplay = (date) => {
    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const changeWeek = (numberOfWeeks) => {
    let newWeek = new Date(currentWeek);
    newWeek.setDate(newWeek.getDate() + numberOfWeeks * 7);
    newWeek.setDate(newWeek.getDate() + (5 - newWeek.getDay()));
    setCurrentWeek(newWeek);
  };
  
  const createMarketCircles = (marketRank) => {
    return marketData
      .filter(market => market.rank === marketRank)
      .map(market => (
        <Circle
          key={market.id}
          center={[market.lat, market.lng]}
          radius={20000}
          pathOptions={{ color: market.color, fillColor: market.color }}
        />
      ));
  };

  const createTourMarkers = (tourNumber) => {
    return tourData
      .filter(tour => tour.number === String(tourNumber) && parseYearFromDateString(tour.date) === parseInt(selectedYear))
      .map(tour => (
        <Marker key={tour.id} position={[tour.lat, tour.lng]} icon={createCustomIcon(tourColors[tour.number])}>
          <Popup>
            {tour.location} <br />
            {tour.date}
          </Popup>
        </Marker>
      ));
  };
  

  const drawTourPathsForTour = (tourNumber) => {
    const sortedTourData = tourData
      .filter(tour => tour.number === String(tourNumber))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    let lastEventOfPreviousWeek = null;
    let firstEventOfCurrentWeek = null;
    let firstEventOfNextWeek = null;
    sortedTourData.forEach(tour => {
      const tourDate = parseDateString(tour.date);
      if (tourDate < currentWeek && (!lastEventOfPreviousWeek || tourDate > parseDateString(lastEventOfPreviousWeek.date))) {
        console.log(`${tour.date} | Tour ${tour.number} - last event of previous week: ${tour.location}`)
        lastEventOfPreviousWeek = tour;
      }
      if (tourDate >= currentWeek && (!firstEventOfCurrentWeek || tourDate < parseDateString(firstEventOfCurrentWeek.date))) {
        console.log(`${tour.date} | Tour ${tour.number} - first event of current week: ${tour.location}`)
        firstEventOfCurrentWeek = tour;
      }
      if (tourDate > currentWeek && (!firstEventOfNextWeek || tourDate < parseDateString(firstEventOfNextWeek.date))) {
        console.log(`${tour.date} | Tour ${tour.number} - first event of next week: ${tour.location}`)
        firstEventOfNextWeek = tour;
      }
    });

    if ((lastEventOfPreviousWeek && firstEventOfCurrentWeek) || (firstEventOfCurrentWeek && firstEventOfNextWeek)) {
      const path = [
        [parseFloat(lastEventOfPreviousWeek.lat), parseFloat(lastEventOfPreviousWeek.lng)],
        [parseFloat(firstEventOfCurrentWeek.lat), parseFloat(firstEventOfCurrentWeek.lng)],
        [parseFloat(firstEventOfNextWeek.lat), parseFloat(firstEventOfNextWeek.lng)]
      ];

      return (
          <Polyline
            key={`${tourNumber}-${lastEventOfPreviousWeek.id}-${firstEventOfCurrentWeek.id}`}
            positions={path}
            color={'#' + tourColors[tourNumber]}
          />
      );
    }
    return null;
  };

  return (
    <div>

      <div className='map-controls'>
        <button onClick={() => changeWeek(-1)}>Previous Week</button>
        <span style={{ margin: '0 20px' }}><span style={{fontWeight: 'bold'}}>Current Week: </span>{formatWeekDisplay(currentWeek)}</span>
        <button onClick={() => changeWeek(1)}>Next Week</button>
      </div>
      <div className='map-controls'>
        <select onChange={e => setSelectedYear(e.target.value)} value={selectedYear}>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
      </div>
      <div className='map'>
        <MapContainer center={[35.8283, -100.5795]} zoom={5} style={{ height: '900px', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; OpenStreetMap contributors'
          />
          <LayersControl position="topright">
            {/* Market Rank Layers */}
            <LayersControl.Overlay name="Market Rank A">
              <LayerGroup>{createMarketCircles('A')}</LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Market Rank B">
              <LayerGroup>{createMarketCircles('B')}</LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Market Rank C">
              <LayerGroup>{createMarketCircles('C')}</LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Market Rank D">
              <LayerGroup>{createMarketCircles('D')}</LayerGroup>
            </LayersControl.Overlay>
            
            {/* Tour Layers */}
            <LayersControl.Overlay name="Tour 1">
              <LayerGroup>
                {createTourMarkers(1)}
                {drawTourPathsForTour('1')}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Tour 2">
              <LayerGroup>
                {createTourMarkers(2)}
                {drawTourPathsForTour('2')}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Tour 3">
              <LayerGroup>
                {createTourMarkers(3)}
                {drawTourPathsForTour('3')}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
        <Legend />
      </div>
    </div>
  )
}

export default MainMap;

