import { Box, Button, Grid, LinearProgress, TextField } from '@mui/material';
import React from 'react'
import { UpdateUserProfile } from '../../../middlewares/auth';
import { FiClipboard, FiRefreshCcw } from 'react-icons/fi';
import { useAuth } from '../../../context/AuthContext';

const UserDetailForm = ({
    formFields,
    handleChange,
    handleUpdate,
    copyToClipboard,
    copied,
    generateRefCode,
}) => {

    const { refCode, loading, loading2 } = useAuth()

    return (
        <Grid
            container
            spacing={2}
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    handleUpdate()
                }
            }}
        >
            {Object.entries(formFields).map(([key, value]) => {
                if (key !== "reference_code" && key !== "is_active") {
                    return (
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                onChange={(e) => handleChange({ ...formFields, [key]: e.target.value })}
                                value={value}
                                sx={{ margin: "10px 0", textTransform: "capitalize" }}
                                fullWidth
                                id={`outlined-basic-${key}`}
                                label={key.replace("_", " ")}
                                variant="outlined"
                            />
                        </Grid>
                    )
                }
            })}
            <Grid item xs={12} sm={12} md={12}>
                <h2>Reference Code</h2>
                <div className="copy-to-clipboard-button">
                    <p>{copied ? 'Copied!' : refCode === "" ? localStorage.getItem("reference_code") : refCode}</p>
                    <Button onClick={generateRefCode} disabled={loading2} variant="contained" sx={{ borderRadius: "100%", minWidth: 10, height: 50 }}>
                        <FiRefreshCcw size={20} />
                    </Button>
                </div>
                <Button variant="contained" sx={{ padding: 2, marginRight: 2 }}>
                    <FiClipboard size={20} onClick={() => copyToClipboard(localStorage.getItem("reference_code"))} />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{textAlign:"end"}}>
                <Button sx={{ marginTop: 5 }} variant="contained" onClick={handleUpdate} disabled={loading}>Update</Button>
            </Grid>
        </Grid>
    )
}

export default UserDetailForm;