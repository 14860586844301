import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

export default function SpeedDialButton({ actions, onClick }) {
    return (
        <Box sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 1000 }}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={onClick}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}
