import React from 'react';
import { Button } from '@mui/material';
import { FiRefreshCcw } from 'react-icons/fi';

const NetworkError = ({ pathname }) => {
    const formattedPageName = pathname.replace("/", "").split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    return (
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minHeight: "70vh" }}>
            <div style={{
                backgroundColor: "rgb(238 238 244)",
                padding: "10px 20px 20px 20px",
                marginTop: "20px",
                borderRadius: "10px"
            }}>
                <p style={{ margin: 0, fontWeight: "900", fontSize: "80px", color: "#3B4FDC" }}>404</p>
                <p style={{ margin: 0, fontSize: "25px", fontWeight: "600" }}>{formattedPageName} Network Error</p>
                <div style={{ marginTop: "20px" }}>
                    <Button onClick={() => window.location.reload()} variant="contained" sx={{ borderRadius: "100%", minWidth: 10, height: 50 }}>
                        <FiRefreshCcw size={20} />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NetworkError;
