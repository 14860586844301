import axiosInstance from '../services';

export const HandleLogin = async (body) => {
    try {
        const response = await axiosInstance.post(`/login/`, body);
        if (response.status === 200 || response.status === 201) {
            const { access_token, refresh_token } = response.data;
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("refresh_token", refresh_token);
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const HandleSignup = async (body) => {
    try {
        const response = await axiosInstance.post(`/user/`, {
            ...body,
            reference_code: {
                code: body.reference_code
            }
        });

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const HandleGenerateRefCode = async () => {
    try {
        const response = await axiosInstance.post(`/generate_ref_code/`);

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const GetUserProfile = async (id) => {
    try {
        const response = await axiosInstance.get(`/user/${id}/`);

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const UpdateUserProfile = async (id, body) => {
    try {
        const response = await axiosInstance.patch(`/user/${id}/`, body);

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const DeleteUserAccount = async (id) => {
    try {
        const response = await axiosInstance.delete(`/user/${id}/`);

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}

export const GenerateAccessToken = async (id) => {
    try {
        const response = await axiosInstance.get(`/user/${id}/`);

        if (response.status === 200 || response.status === 201) {
            return response;
        }
    } catch (error) {
        return error.response;
    }
}