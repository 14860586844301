import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services';

const asyncFetchCompetitionData = async (type, url, body) => {
    try {
        const response =
            type === "GET" ? await axiosInstance.get(`${url}`) :
            type === "POST" ? await axiosInstance.post(`${url}`, { ...body }) :
            type === "PATCH" ? await axiosInstance.patch(`${url}`, { ...body }) :
            type === "DELETE" ? await axiosInstance.delete(`${url}`) : null
        return response;
    } 
    catch (error) {
        return error;
    }
};

// Thunks

export const fetchJQCompetition = createAsyncThunk(
    'fetchJQCompetition',
    async () => {
        const url = `/jq_competition/`;
        const res = await asyncFetchCompetitionData("GET", url);
        return res;
    }
);

export const CreateJQCompetition = createAsyncThunk(
    'CreateJQCompetition',
    async (body) => {
        const url = `/jq_competition/`;
        const res = await asyncFetchCompetitionData("POST", url, body);
        return res;
    }
);

export const EditJQCompetition = createAsyncThunk(
    'EditJQCompetition',
    async (body) => {
        const url = `/jq_competition/${body.id}/`;
        const res = await asyncFetchCompetitionData("PATCH", url, body.fields);
        return res;
    }
);

export const DeleteJQCompetition = createAsyncThunk(
    'DeleteJQCompetition',
    async (body) => {
        const url = `/jq_competition/${body}/`;
        const res = await asyncFetchCompetitionData("DELETE", url);
        return res;
    }
);