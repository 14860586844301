import React, { useState } from 'react'
import Form from './UI-components/Form/form'
import UserDetailForm from './UI-components/Form/form2'
import { Box, Button, LinearProgress } from '@mui/material'
import { DeleteUserAccount, HandleGenerateRefCode, UpdateUserProfile } from '../middlewares/auth'
import { useAuth } from '../context/AuthContext'
import { FiClipboard } from 'react-icons/fi';
import { FiRefreshCcw } from 'react-icons/fi';
import jwtDecoder from '../config/jwt-decoder'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Profile = () => {

    const {
        formFields,
        setFormFields,
        setLoading,
        loading,
        loading3,
        setLoading2,
        setLoading3,
        setRefCode,
    } = useAuth()
    const accessToken = localStorage.getItem("access_token")
    const [copied, setCopied] = useState(false);
    const [success, setSuccess] = useState(false);
    const [success2, setSuccess2] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    async function handleUpdate() {
        if (!accessToken) {
            return;
        }
        try {
            const decoded = jwtDecoder(accessToken);
            setLoading(true);

            // Remove reference_code from formFields if it's not supposed to be included
            const { reference_code, ...bodyWithoutReferenceCode } = formFields;

            const res = await UpdateUserProfile({
                id: decoded.sub,
                token: localStorage.getItem("access_token"),
                body: bodyWithoutReferenceCode  // Send updated body without reference_code
            });

            if (res.status === 200) {
                console.log("profile update res ====>", res);
                setLoading(false);
                setSuccess(true); // Set success to true after successful update
            } else {
                console.log(res);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const generateRefCode = async () => {
        console.log("working..");
        setLoading2(true)
        try {
            const response = await HandleGenerateRefCode({ token: localStorage.getItem("access_token") })
            if (response.status === 200) {
                setSuccess2(true)
                setLoading2(false)
                localStorage.setItem("reference_code", response.data.reference_code)
                setRefCode(response.data.reference_code)
            }
        } catch (error) {
            setLoading2(false)
            setSuccess2(false)
            console.log(error);
        }
    }

    const handleDeleteAccount = async () => {
        if (!accessToken) {
            return;
        }
        try {
            setLoading3(true)
            const decoded = jwtDecoder(accessToken);
            const response = await DeleteUserAccount({
                id: decoded.sub,
                token: localStorage.getItem("access_token"),
            })
            console.log(response);
            if (response.status === 200) {
                setLoading3(false)
                localStorage.removeItem("access_token")
                localStorage.removeItem("reference_code")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (error) {
            setLoading3(false)
            console.log(error);
        }
    }

    return (
        <div style={{ margin: "20px" }}>
            {(loading === true || loading3 === true) ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            ) : ""}
            <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", backgroundColor: "white", padding: "30px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h2>Personal Details</h2>
                    <Button disabled={loading3} onClick={handleDeleteAccount} variant="contained" sx={{ backgroundColor: "red" }}>Delete my account</Button>
                </Box>
                <UserDetailForm
                    type="user-details"
                    formFields={formFields}
                    handleChange={setFormFields}
                    handleUpdate={handleUpdate}
                    copyToClipboard={copyToClipboard}
                    copied={copied}
                    generateRefCode={generateRefCode}
                    loading={loading}
                />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={success} // Show Snackbar when success is true
                    autoHideDuration={3000}
                    message="Profile Updated"
                    onClose={() => setSuccess(false)} // Close Snackbar when clicked or after timeout
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSuccess(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={success2} // Show Snackbar when success is true
                    autoHideDuration={3000}
                    message="Reference Code Generated"
                    onClose={() => setSuccess2(false)} // Close Snackbar when clicked or after timeout
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSuccess2(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </div>
        </div>
    )
}

export default Profile;
