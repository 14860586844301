import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
    name: "company",
    initialState: {
        company: "jq",
    },
    reducers: {
        setCompanyName: (state, { payload }) => {
            state.company = payload
        },
    }
})

export const { setCompanyName } = companySlice.actions
export default companySlice.reducer;