import React from 'react';

const Form = (props) => {
    const { type, handleSubmit, switchForm, validationError, setFormFields, formFields = {} } = props;

    //console.log("Current formFields in Form.js:", formFields);

    if (type === "login" && (!formFields.email || !formFields.password)) {
    } else if (Object.keys(formFields).length === 0) {
        return <div>checking for credentials...</div>;
    }
    
    return (
        <>
            {Object.entries(formFields).map(([k, v]) => (
                k === "is_active" ? null : (
                    <input
                        key={k}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && v !== "") {
                                handleSubmit(e);
                            }
                        }}
                        type={k === "password" ? "password" : "text"}
                        placeholder={k.replace("_", " ")}
                        value={k === "reference_code" ? v?.code : formFields[k]}
                        onChange={(e) => setFormFields((prevFormFields) => ({ ...prevFormFields, [k]: e.target.value }))}
                    />
                )
            ))}
            {validationError && <p style={{ color: "red" }}>{validationError}</p>}
            {type === "login" ? (
                <p style={{ color: "white" }}>
                    Have a ref code? <span onClick={switchForm} style={{ cursor: "pointer", textDecoration: "underline" }}>Create Account</span>
                </p>
            ) : type === "signup" ? (
                <p style={{ color: "white" }}>
                    Already have an account? <span onClick={switchForm} style={{ cursor: "pointer", textDecoration: "underline" }}>Login</span>
                </p>
            ) : null}
            {type === "signup" ? (
                <button
                    onClick={handleSubmit}
                    className={(formFields.first_name === "" || formFields.last_name === "" || formFields.email === "" || formFields.password === "" || formFields.reference_code?.code === "") ? "signup-disable" : "signup-enable"}
                    disabled={(formFields.first_name === "" || formFields.last_name === "" || formFields.email === "" || formFields.password === "" || formFields.reference_code?.code === "")}>
                    Sign Up
                </button>
            ) : type === "refCode" ? (
                <button
                    onClick={handleSubmit}
                    className={formFields.code?.length < 6 ? "signup-disable" : "signup-enable"}
                    disabled={formFields.code?.length < 6}>
                    Generate
                </button>
            ) : (
                <button
                    data-testid="login-button"
                    onClick={handleSubmit}
                    className={(formFields.email === "" || formFields.password === "") ? "signup-disable" : "signup-enable"}
                    disabled={(formFields.email === "" || formFields.password === "")}>
                    Sign In
                </button>
            )}
        </>
    );
};

export default Form;