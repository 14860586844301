import React, { useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';

const MetricToggle = ({ selectedMetric, setSelectedMetric }) => {

  const metrics = ['spend', 'inline_link_clicks', 'inline_post_engagement', 'impressions'];

  const metricDisplayNames = {
    spend: 'Spend',
    impressions: 'Impressions',
    inline_post_engagement: 'Post Engagement',
    inline_link_clicks: 'Link Clicks'
  };

  return (
    <ButtonGroup
      style={{
        border: "1px solid #F1F5F9",
        borderRadius: '4px',
        minWidth: "200px",
        maxWidth: "527px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {metrics.map((metric) => (
        <Button
          key={metric}
          onClick={() => setSelectedMetric(metric)}
          variant={selectedMetric === metric ? 'contained' : 'outlined'}
          style={{
            border: '1px solid #F1F5F9',
            backgroundColor: selectedMetric === metric ? '#f4f4f4' : 'transparent',
            color: 'black',
            fontWeight: 'bold',
            textTransform: 'none'
          }}
        >
          {metricDisplayNames[metric]}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default MetricToggle;
