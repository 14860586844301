import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/App.css';
import '../styles/fonts.css'
import { MoonLoader } from 'react-spinners';
import PieChart from '../assets/icons/pie-chart-alt.svg';
import ChevronDown from '../assets/icons/chevron-down.svg';
import GridAlt from '../assets/icons/grid-alt.svg';
import CalenderIcon from '../assets/icons/calender-alt-2.svg';

const Sidebar = ({ markets }) => {
  
  const [dropdownStates, setDropdownStates] = useState({
    dashboards: true,
    markets: true,
  });
  
  const navigate = useNavigate();

  const navigateToMarket = (market) => {
    const marketId = encodeURIComponent(market.toLowerCase());
    navigate(`/market/${marketId}`);
  };

  const navigateToMap = () => {
    navigate('/map');
  }

  const navigateToForecast = () => {
    navigate('/forecast')
  }

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({ ...prevState, [dropdown]: !prevState[dropdown] }));
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="sidebar">
      <div className="logo-container" onClick={handleLogoClick}>
        <img src="/logo.png" alt="Family Quest Entertainment Logo" className="logo" />
        <span className="company-name">FQE Analytics</span>
      </div>
      <div className="sidebar-menu">
        <p className="menu-header">MENU</p>
        <div className="menu-section">
          <div className="menu-item" onClick={() => toggleDropdown('dashboards')}>
            <img src={GridAlt} alt="Dashboard" className="menu-icon" />
            <span>Dashboards</span>
            <img src={ChevronDown} alt="Expand" className={`menu-expand-icon ${!dropdownStates.dashboards ? 'rotate-up' : ''}`} />
          </div>
          {dropdownStates.dashboards && (
            <div className="dropdown-menu">
              {/* <div className="dropdown-item" onClick={navigateToForecast}>Forecast</div> */}
              <div className="dropdown-item" onClick={() => navigate("/marketing")}>Marketing</div>
              <div className="dropdown-item" onClick={navigateToMap}>Tour Map</div>
              <div className='dropdown-item' onClick={() => navigate("/ZipMap")}>Zip Sales Map</div>
              <div className='dropdown-item' onClick={() => navigate("/competition")}>Competition</div>
              <div className='dropdown-item' onClick={() => navigate("/drilldown")}>Drilldown</div>
              {/* <div className='dropdown-item'>Merchandise</div>
              <div className='dropdown-item' onClick={() => navigate("/score-card")}>Score Card</div> */}
            </div>
          )}
        </div>
        <div className="menu-section">
          <div className="menu-item" onClick={() => toggleDropdown('markets')}>
            <img src={PieChart} alt="Dashboard" className="menu-icon" />
            <span>Markets</span>
            <img src={ChevronDown} alt="Expand" className={`menu-expand-icon ${!dropdownStates.markets ? 'rotate-up' : ''}`} />
          </div>
          {dropdownStates.markets && (
            <div className="dropdown-menu">
              {markets?.length === 0 ? (
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "120px",
                }}>
                  <MoonLoader color={'#3C50E0'} loading={true} size={28} data-testid="moon-loader"/>
                </div>
              ) : markets.map((market, index) => (
                <div key={index} className="dropdown-item" onClick={() => navigateToMarket(market)}>
                  {market}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="menu-section">
          <div className="menu-item">
            <img src={CalenderIcon} alt="Dashboard" className="menu-icon" />
            <span>Calendar</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
