import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import ModalComp from './UI-components/Modal/modal';
import DataTable from './UI-components/Table/table';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { CreateJQCompetition, DeleteJQCompetition, EditJQCompetition, fetchJQCompetition } from '../services/competition';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import SpeedDialButton from './UI-components/SpeedDial';
import '../styles/App.css';
import '../styles/fonts.css';
import { Add, Event } from '@mui/icons-material';
import { setCompetitions, setLoading } from '../store/competitionSlice';
import dayjs from 'dayjs';

const Competition = () => {

    const { competitions, loading } = useSelector((state) => state.competitions)
    const [competitionId, setCompetitionId] = useState(null);
    const [formFields, setFormFields] = useState({
        category: "",
        show: "",
        venue: "",
        city: "",
        state: "",
        opening_date: null,
        closing_date: null,
        perfs: "",
        notes: "",
    });
    const [editFields, setEditFields] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [error, setError] = useState({});
    const actions = [{ icon: <Event />, name: 'Create' }];
    const categories = ["Dinosaur", "Circus", "Sport", "Theatrical", "Brick", "Ice", "Immersive"];

    const dispatch = useDispatch()

    const handleChange = (key, value) => {
        setFormFields((prevFields) => ({
            ...prevFields,
            [key]: (key === "opening_date" || key === "closing_date") ? dayjs(value).format('YYYY-MM-DD') : value 
        }));
    };

    function showToast(status, message) {
        const statusCode = [200, 201, 204];
        if (statusCode.includes(status)) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }
            });
            dispatch(fetchJQCompetition())
        }
        else {
            enqueueSnackbar("Someting went wrong", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }
            });
        }
    }

    async function handleCreate() {
        const fields = { ...formFields };
        const errors = {};
        let reqFields = ["category", "venue", "show", "opening_date", "closing_date"]
        let newArr = [...competitions]
        let generateID = newArr?.length ? newArr?.sort((a, b) => b.u_id - a.u_id)[0].u_id + 1 : 1;
        const payload = {
            ...formFields,
            u_id: generateID,
            opening_date: formFields.opening_date ? new Date(formFields.opening_date).toISOString().split('T')[0] : null,
            closing_date: formFields.closing_date ? new Date(formFields.closing_date).toISOString().split('T')[0] : null,
        };
        Object.entries(fields).forEach(([key, value]) => {
            const formattedKey = key.split("_").map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(" ");
            if (reqFields.includes(key) && (value === "" || value === null)) {
                errors[key] = `${formattedKey} is required`
            }
        })
        if (Object.keys(errors).length > 0) {
            setError(errors)
            return;
        }
        setError({})
        dispatch(setLoading(true))
        const res = await dispatch(CreateJQCompetition(payload))
        if (res?.payload?.status === 201) {
            showToast(res?.payload?.status, "Competiton Created successfully")
            dispatch(setLoading(false))
            dispatch(setCompetitions([...competitions, res.payload.data]))
            setShowModal(false)
        }
        if (res?.payload?.response?.status === 400) {
            showToast(res?.payload?.response?.status, "Someting went wrong")
            setError(res?.payload?.response?.data?.error)
            dispatch(setLoading(false))
        }
    }

    async function handleUpdate() {
        dispatch(setLoading(true))
        const res = await dispatch(EditJQCompetition({ id: competitionId, fields: editFields }))
        if (res?.payload?.status === 200) {
            dispatch(setLoading(false))
            showToast(res?.payload?.status, "Competiton Updated successfully")
            const newArr = [...competitions];
            const findCompInd = newArr.findIndex((comp) => comp.id === competitionId)
            newArr[findCompInd] = res?.payload?.data
            setCompetitions(newArr)
            setShowEditModal(false)
        }
        if (res?.payload?.status === 404 || res?.payload?.response?.status === 404) {
            dispatch(setLoading(false))
            showToast(res?.payload?.response?.status, "Someting went wrong")
        }
    }

    async function handleDelete(id) {
        dispatch(setLoading(true))
        const res = await dispatch(DeleteJQCompetition(id))
        if (res?.payload?.status === 204) {
            const filterArr = [...competitions].filter((f) => f.id !== id);
            setCompetitions(filterArr)
            showToast(res?.payload?.status, "Competiton Deleted successfully")
            dispatch(setLoading(false))
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'show', headerName: 'Show', width: 150 },
        { field: 'venue', headerName: 'Venue', width: 150 },
        { field: 'city', headerName: 'City', width: 150 },
        { field: 'state', headerName: 'State', width: 150 },
        { field: 'opening_date', headerName: 'Opening Date', width: 150 },
        { field: 'closing_date', headerName: 'Closing Date', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => {
                return (
                    <div>
                        <Button sx={{ marginRight: 3 }} variant="contained" onClick={() => handleDelete(params.row.id)}>Delete</Button>
                        <Button variant="contained" onClick={() => {
                            setShowEditModal(true)
                            setCompetitionId(params.row.id)
                            setFormFields({
                                category: params.row.category,
                                show: params.row.show,
                                venue: params.row.venue,
                                city: params.row.city,
                                state: params.row.state,
                                opening_date: params.row.opening_date,
                                closing_date: params.row.closing_date,
                                perfs: params.row.perfs,
                                notes: params.row.notes,
                            })
                        }}>Edit</Button>
                    </div>
                )
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchJQCompetition())
    }, []);

    console.log(formFields);

    return (
        <div style={{ position: "relative", marginTop: "40px" }}>
            <SpeedDialButton
                actions={actions}
                onClick={() => {
                    setShowModal(true)
                    setFormFields({
                        category: "",
                        show: "",
                        venue: "",
                        city: "",
                        state: "",
                        opening_date: null,
                        closing_date: null,
                        perfs: "",
                        notes: "",
                    })
                }} />
            <SnackbarProvider />
            <div>
                <Typography variant="h5" color="grey" fontWeight="600" sx={{ marginBottom: 0 }}>All Competitions ({competitions?.length})</Typography>
                <div className='add-event-container'>
                    {showModal && (
                        <ModalComp handleClose={() => setShowModal(false)} open={showModal} modalTitle={<p style={{ margin: 0, fontWeight: "600" }}>Create New Competition</p>}>
                            <Grid container spacing={2} mt={1}>
                                {Object.entries(formFields).map(([k, v]) => {
                                    return k === "opening_date" || k === "closing_date" ? (
                                        <Grid item xs={12} sm={12} md={6} key={k}>
                                            <div className='my-date-picker'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={v ? dayjs(v) : null}
                                                            onChange={(e) => handleChange(k, e)}
                                                            label={(<>{k.replace("_", " ")} <span style={{ color: "red" }}>*</span></>)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            {error && <p style={{ color: "red", margin: "10px 0 0 0" }}>{error[k]}</p>}
                                        </Grid>
                                    ) : k === "category" ? (
                                        <Grid item xs={12} sm={12} md={12} key={k}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={v}
                                                label="Select Category"
                                                onChange={(e) => handleChange(k, e.target.value)}
                                                sx={{ width: "100%" }}
                                            >
                                                {categories.map((cat) => <MenuItem value={cat}>{cat}</MenuItem>)}
                                            </Select>
                                            {error && <p style={{ color: "red", margin: "10px 0 0 0" }}>{error[k]}</p>}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={6} key={k}>
                                            <TextField
                                                onChange={(e) => handleChange(k, e.target.value)}
                                                value={v}
                                                sx={{ margin: "10px 0", textTransform: "capitalize" }}
                                                fullWidth
                                                id="outlined-basic"
                                                label={(
                                                    <>
                                                        {k.replace("_", " ")}
                                                        <span style={{ color: "red" }}>
                                                            {k === "city" || k === "state" || k === "perfs" || k === "notes" ? "" : "*"}
                                                        </span>
                                                    </>
                                                )}
                                                variant="outlined"
                                            />
                                            {error && <p style={{ color: "red", margin: 0 }}>{error[k]}</p>}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <div style={{ textAlign: "right" }}>
                                <Button disabled={loading} style={{ width: "200px", fontWeight: "bold" }} variant="contained" onClick={handleCreate}>{loading ? <CircularProgress size={30} /> : "Create"}</Button>
                            </div>
                        </ModalComp>
                    )}
                </div>
                <DataTable
                    columns={columns}
                    data={competitions}
                    handleUpdateSettings={handleUpdate}
                    formFields={formFields}
                    handleDelete={handleDelete}
                    handleChange={handleChange}
                    setFormFields={setFormFields}
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    setEditFields={setEditFields}
                    editFields={editFields}
                    loading={loading}
                    categories={categories}
                />
            </div>
        </div>
    );
};

export default Competition;