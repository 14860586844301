import React, { useState, useRef, useEffect } from 'react';
import AccountMenu from './UI-components/Menu/menu';
import { useNavigate } from 'react-router-dom';
import { RxHamburgerMenu, RxCaretLeft } from "react-icons/rx";
import SwitchTab from './UI-components/Tabs/Tabs';
import InputComp from './UI-components/Input/input';
import { useDispatch } from 'react-redux';
import { setCompanyName } from '../store/companySlice';

const Topbar = ({ markets, openSidebar, setOpenSidebar }) => {

  const [searchMarkets, setSearchMarkets] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch()

  let id = 0;

  const market = markets.map((f) => {
    return { market: f, id: id += 1 };
  });

  const handleSearchMarket = (marketName) => {
    const search = market.filter((market) => market.market.toLowerCase().includes(marketName.toLowerCase()));
    setSearchMarkets(search);
  };

  const navigateToMarket = (market) => {
    const marketId = encodeURIComponent(market.toLowerCase());
    navigate(`/market/${marketId}`);
    setSearchMarkets([]);
    setSearchValue("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchMarkets([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCompanyChange = (e) => {
    setCompanyName(e);
    dispatch(setCompanyName(e))
  };

  useEffect(() => {
    dispatch(setCompanyName("jq"))
  }, [])

  const style = {
    left: openSidebar ? "250px" : "0"
  };

  return (
    <div className="top-bar" style={style}>
      {openSidebar ? (
        <RxCaretLeft size={32} style={{ cursor: "pointer" }} onClick={() => setOpenSidebar(false)} />
      ) : (
        <RxHamburgerMenu size={27} style={{ cursor: "pointer" }} onClick={() => setOpenSidebar(true)} />
      )}
      <div className="search-container">
        {/* <input
          type="search"
          placeholder="Search..."
          onChange={(e) => {
            handleSearchMarket(e.target.value);
            setSearchValue(e.target.value);
          }}
          value={searchValue}
        /> */}
        <InputComp
          placeholder="Search market"
          onChange={(e) => {
            handleSearchMarket(e.target.value);
            setSearchValue(e.target.value);
          }}
          value={searchValue}
        />
        {searchMarkets.length > 0 && (
          <div
            ref={dropdownRef}
            style={{ zIndex: "10000", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", backgroundColor: "white", height: "50vh", overflowY: "scroll", width: "85%", position: "absolute", top: 40, left: 0, padding: "10px 30px" }}
          >
            {searchMarkets.map((market) => (
              <div key={market.id} onClick={() => {
                navigateToMarket(market.market)
                setSearchValue(market.market)
              }}>
                <p style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>{market.market}</p>
              </div>
            ))}
          </div>
        )}
        {/* <img src="/search-icon.png" alt="Search" className="search-icon" /> */}
      </div>
      <div className="company-toggle">
        <SwitchTab onChange={handleCompanyChange} />
      </div>
      {/* <div className="company-toggle">
        <label>
          <input
            type="radio"
            value="jq"
            checked={companyName === "jq"}
            onChange={handleCompanyChange}
          />
          JQ
        </label>
        <label>
          <input
            type="radio"
            value="bfl"
            checked={companyName === "bfl"}
            onChange={handleCompanyChange}
          />
          BFL
        </label>
      </div> */}
      <AccountMenu />
    </div>
  );
};

export default Topbar;