import { Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'

const MarketingDataBox = ({ market }) => {
    return (
        <Card>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography
                            color="text.secondary"
                            fontSize={18}
                            fontWeight="600"
                        >
                            {market.title}
                        </Typography>
                        <Typography variant="h5" fontWeight={'bold'}>
                            {market.formattedValue}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Last Year: {market.lastYearValue}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{ mt: 2 }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color={market.percent >= 0 ? 'success.main' : 'error.main'}
                            variant="body2"
                        >
                            {market.percent}%
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default MarketingDataBox;
