import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketureAllEventIDs, fetchTicketureZipCodeByEventID } from '../services/ticketure';
import { MapContainer, TileLayer, Circle, LayersControl, LayerGroup, Popup } from 'react-leaflet';
import { Autocomplete, TextField, Button, Box } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import '../styles/MapLegend.css';

const ZipMap = () => {
  const [zipData, setZipData] = useState(null);
  const [selectedEventIDs, setSelectedEventIDs] = useState([]);
  const [appliedEventIDs, setAppliedEventIDs] = useState([]);
  const [eventSalesData, setEventSalesData] = useState([]);

  const dispatch = useDispatch();
  const ticketureState = useSelector((state) => state.ticketure);
  const companyName = useSelector((state) => state.company.company);
  const allEventIDs = ticketureState.allEventIds;

  // Fetch all event IDs when the component mounts or companyName changes
  useEffect(() => {
    dispatch(fetchTicketureAllEventIDs());
  }, [dispatch, companyName]);

  // Fetch zip data (assuming zip.json is in your public folder)
  useEffect(() => {
    fetch('/zip.json')
      .then((response) => response.json())
      .then((data) => setZipData(data))
      .catch((error) => console.error('Error loading zip data:', error));
  }, []);

  // Fetch sales data for the applied event IDs
  useEffect(() => {
    if (appliedEventIDs.length > 0) {
      const fetchAllEventData = async () => {
        const allEventData = await Promise.all(
          appliedEventIDs.map((eventID) => dispatch(fetchTicketureZipCodeByEventID(eventID)))
        );

        // Extract payloads and add event_id to each sale
        const rawData = allEventData.flatMap((response, index) =>
          response.payload.map((sale) => ({
            ...sale,
            event_id: appliedEventIDs[index],
          }))
        );

        // Group sales data by event_id
        const groupedData = appliedEventIDs.map((eventID) => ({
          event_id: eventID,
          sales: rawData.filter((sale) => sale.event_id === eventID),
        }));

        setEventSalesData(groupedData);
      };
      fetchAllEventData();
    } else {
      setEventSalesData([]);
    }
  }, [dispatch, appliedEventIDs, companyName]);

  // Define colors for each event
  const customColors = ['#9B59B6', '#3498DB', '#1ABC9C', '#f58231', '#F1C40F'];
  const colorMap = appliedEventIDs.reduce((acc, eventId, index) => {
    acc[eventId] = customColors[index % customColors.length];
    return acc;
  }, {});

  // Handle apply button click
  const handleApply = () => {
    setAppliedEventIDs([...selectedEventIDs]);
  };

  return (
    <div>
      <div className="map-container" id="market-map" style={{ height: 'fit-content', width: '90%' }}>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Autocomplete
              multiple
              options={allEventIDs || []}
              getOptionLabel={(option) => option}
              value={selectedEventIDs}
              onChange={(event, newValue) => setSelectedEventIDs(newValue)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Event IDs" />}
              style={{ width: '80%' }}
            />
            <Button variant="contained" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Box>

        <MapContainer center={[35.8283, -100.5795]} zoom={4} style={{ height: '400px', width: '100%' }}>
          <TileLayer
            attribution='&copy; OpenStreetMap contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LayersControl position="topright">
            {eventSalesData.map((eventData) => (
              <LayersControl.Overlay key={eventData.event_id} name={eventData.event_id} checked>
                <LayerGroup>
                  {eventData.sales
                    .filter((sale) => sale.zip_code && zipData && zipData[sale.zip_code])
                    .map((sale) => {
                      const coords = zipData[sale.zip_code];
                      const radius = Math.sqrt(sale.tickets_purchased) * 500;
                      const color = colorMap[eventData.event_id];
                      return coords && !isNaN(radius) ? (
                        <Circle
                          key={`${eventData.event_id}-${sale.zip_code}`}
                          center={[coords.latitude, coords.longitude]}
                          radius={radius}
                          color={color}
                          fillColor={color}
                          fillOpacity={0.2}
                        >
                          <Popup>
                            <div>
                              <strong>Event ID:</strong> {eventData.event_id}
                              <br />
                              <strong>Zip Code:</strong> {sale.zip_code}
                              <br />
                              <strong>Tickets Purchased:</strong> {sale.tickets_purchased}
                            </div>
                          </Popup>
                        </Circle>
                      ) : null;
                    })}
                </LayerGroup>
              </LayersControl.Overlay>
            ))}
          </LayersControl>
        </MapContainer>

        <div className="legend">
          {eventSalesData.map((eventData) => (
            <div key={eventData.event_id} className="legend-item">
              <span className="legend-color" style={{ backgroundColor: colorMap[eventData.event_id] }}></span>
              {eventData.event_id}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ZipMap;