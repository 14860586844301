import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

const SalesMatrix = ({ projections = [], sliderTimestamp }) => {

  const filteredProjections = projections.filter(event => event.GeneralizedOpenDate > sliderTimestamp);
  const sortedProjections = filteredProjections.sort((a, b) => a.GeneralizedOpenDate - b.GeneralizedOpenDate);
  const [adjustments, setAdjustments] = useState([])

  let allDays = [];
  sortedProjections.forEach(event => {
    const eventDays = Object.keys(event.salesData).concat(Object.keys(event.projections || {}));
    allDays = allDays.concat(eventDays);
  });
  allDays = [...new Set(allDays)].sort((a, b) => Number(a) - Number(b));

  useEffect(() => {
    if (sortedProjections.length > 0 && adjustments.length !== sortedProjections.length) {
      const sortedProjectionsArray = sortedProjections.map(() => 0);
      setAdjustments(sortedProjectionsArray);
    }
  }, [sortedProjections, adjustments.length]);

  const adjustProjection = (index, adjustment) => {
    const newAdjustments = [...adjustments];
    newAdjustments[index] += adjustment;
    setAdjustments(newAdjustments);
  };

  const calculateCumulativeTotal = (event, index) => {
    const adjustmentFactor = 1 + adjustments[index] / 100;
    const allData = { ...event.salesData, ...event.projections };
    return Object.values(allData).reduce((acc, current) => {
      const adjustedValue = current * adjustmentFactor;
      return isNaN(adjustedValue) ? acc : acc + adjustedValue;
    }, 0);
  };

  const generatePathData = (salesData, adjustmentFactor) => {
    const data = Object.entries(salesData).map(([day, value]) => {
      const adjustedValue = value * adjustmentFactor;
      return {
        day: +day,
        value: isNaN(adjustedValue) ? 0 : adjustedValue,
      };
    });

    const xScale = d3.scaleLinear()
      .domain(d3.extent(data, d => d.day))
      .range([0, 100]);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([50, 0]);

    const line = d3.line()
      .x(d => xScale(d.day))
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX);

    return line(data);
  };


  const navigateToMarket = (market) => {
    const marketName = market.split('_').slice(0, 2).join(', ');
    const marketId = encodeURIComponent(marketName.toLowerCase());
    const url = `/market/${marketId}`;
    window.open(url, '_blank');
  };

  const handleExportClick = () => {
    const dataToSend = {
      data: sortedProjections.map((event, index) => {
        const adjustment = adjustments[index];

        const salesDataArray = Object.entries(event.salesData).map(([day, value]) => ({
          day: parseInt(day, 10),
          value,
          Projected: false
        }));

        const projectionsArray = Object.entries(event.projections || {}).map(([day, value]) => ({
          day: parseInt(day, 10),
          value,
          Projected: true
        }));

        const combinedSalesData = [...salesDataArray, ...projectionsArray].sort((a, b) => a.day - b.day);

        return {
          ...event,
          adjustment,
          salesData: combinedSalesData
        };
      })
    };

    console.log(dataToSend)

    fetch('https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/generate-excel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "projections_report.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch(error => {
        console.error('Error during fetch operation:', error.message);
      });
  };

  return (
    <div>
      <div className="export-container">
        <button onClick={handleExportClick}>Export to .xlsx</button>
      </div>
      <table>
        <thead>
          <tr>
            <th className="event-id-column">EVENTID</th>
            <th className='total-tickets'>Forecasted Tickets</th>
            <th>Daily Actuals Trend</th>
            <th>Adjustment</th>
            {allDays.map(day => (
              <th key={day}>{`${day}`}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedProjections.map((event, index) => {
            const adjustmentFactor = 1 + adjustments[index] / 100;
            const pathData = generatePathData(event.salesData, adjustmentFactor);
            const marketId = event.EVENTID;

            return (
              <tr key={index}>
                <td className="event-id-column" onClick={() => navigateToMarket(marketId)}>{event.EVENTID}</td>
                <td>{calculateCumulativeTotal(event, index)}</td>
                <td onClick={() => navigateToMarket(marketId)}>
                  <svg width="100" height="50">
                    <path d={pathData} fill="none" stroke="#1abc9c" />
                  </svg>
                </td>
                <td className="table-cell">
                  <button className="adjust-buttons" onClick={() => adjustProjection(index, -5)}>-</button>
                  <span className="adjust-percentage">{`${adjustments[index]} %`}</span>
                  <button className="adjust-buttons" onClick={() => adjustProjection(index, 5)}>+</button>
                </td>
                {allDays.map(day => {
                  const isProjection = event.projections && event.projections.hasOwnProperty(day);
                  let value = event.salesData[day] ?? (isProjection ? event.projections[day] * adjustmentFactor : 0);
                  value = isNaN(value) ? 0 : Math.round(value);
                  return (
                    <td key={day} style={{ color: isProjection ? '#0984e3' : 'black' }}>
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SalesMatrix;
