import { createSlice } from "@reduxjs/toolkit";

const drilldownSlice = createSlice({
    name: "drilldown",
    initialState: {
        metaInsight: [],
        dailyTicketSales: [],
        loading: {
            isLoading: false,
            type: ""
        },
    },
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setMetaInsightsData: (state, { payload }) => {
            state.metaInsight = payload
        },
        setDailyTicketSalesData: (state, { payload }) => {
            state.dailyTicketSales = payload
        },
    }
})

export const { setMetaInsightsData, setDailyTicketSalesData, setLoading } = drilldownSlice.actions
export default drilldownSlice.reducer;