import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services';

const fetchTicketureData = async (url, company) => {
    console.log(url, company);
    try {
        const response = await axiosInstance.get(`${company}${url}`);
        console.log({ url, response });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
};

// Thunks
export const fetchTicketureAllEventIDs = createAsyncThunk(
    'ticketure/fetchAllEventIDs',
    async (_, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?event_ids=true`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureAllMarkets = createAsyncThunk(
    'ticketure/fetchAllMarkets',
    async (_, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?market_names=true`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureByMarket = createAsyncThunk(
    'ticketure/fetchByMarket',
    async (market, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?market_name=${market}`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureByEventIDs = createAsyncThunk(
    'ticketure/fetchByEventIDs',
    async (eventID, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?event_id=${eventID}`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureByOpenDate = createAsyncThunk(
    'ticketure/fetchByOpenDate',
    async ({ startDate, endDate }, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?generalized_start_date=${startDate}&generalized_end_date=${endDate}`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureByAttendingDate = createAsyncThunk(
    'ticketure/fetchByAttendingDate',
    async ({ startDate, endDate }, { getState, dispatch }) => {
        const url = `/daily_ticket_sales/?start_date=${startDate}&end_date=${endDate}`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchTicketureZipCodeByEventID = createAsyncThunk(
    'ticketure/fetchZipCodeByEventID',
    async (eventID, { getState, dispatch }) => {
        const url = `/zip_code_sales/?event_id=${eventID}`;
        return fetchTicketureData(url, getState().company.company);
    }
);

export const fetchPageViews = createAsyncThunk(
    'pageViews/fetchByDate',
    async ({ startDate, endDate }, { getState, dispatch }) => {
        const url = `/page_views/?start_date=${startDate}&end_date=${endDate}`;
        return fetchTicketureData(url);
    }
);

// metaInsights

const fetchMetaInsightByData = async (url, company) => {
    try {
        const response = await axiosInstance.get(`${company}${url}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
}

export const fetchMetaInsightByDate = createAsyncThunk("fetchMetaInsightByDate", async (body, { getState, dispatch }) => {
    console.log("body1", body);
    const url = `/meta_insight/?start_date=${body.startDate}&end_date=${body.endDate}`
    return fetchMetaInsightByData(url, getState().company.company)
})

export const fetchMetaInsightByDate2 = createAsyncThunk("fetchMetaInsightByDate2", async (body, { getState, dispatch }) => {
    console.log("body1", body);
    const url = `/meta_insight/?start_date=${body.lastYearStartDate}&end_date=${body.lastYearEndDate}`
    return fetchMetaInsightByData(url, getState().company.company)
})

export const fetchMetaInsightByMarket = createAsyncThunk("fetchMetaInsightByMarket", async (market) => {
    const url = `/meta_insight/?market_name=${market}`;
    return fetchMetaInsightByData(url);
})

export const fetchMetaInsigthByEventIDs = createAsyncThunk(
    'ticketure/fetchMetaInsigthByEventIDs',
    async (eventID, { getState, dispatch }) => {
        const url = `/meta_insight/?event_id=${eventID}`;
        return fetchMetaInsightByData(url, getState().company.company);
    }
);

// GaPageViews

const fetchPageViewsData = async (url) => {
    try {
        const companyName = localStorage.getItem('companyName') || 'jq';
        const response = await axiosInstance.get(`${companyName}${url}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const fetchPageViewsByDate = createAsyncThunk("fetchPageViewsByDate", async (body) => {
    const url = `/page_views/?start_date=${body.startDate}&end_date=${body.endDate}`;
    return fetchPageViewsData(url);
})