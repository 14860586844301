import React from 'react';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Form from './UI-components/Form/form';
import { HandleLogin } from '../middlewares/auth';

const Login = () => {
  const {
    loginFields,
    setLoginFields,
    setError,
    loading,
    setLoading,
    validationError,
    setValidationError,
    emailRegex,
    setIsAuthenticated,
  } = useAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailRegex.test(loginFields.email)) {
      setValidationError("");
      setLoading(true);

      try {
        const response = await HandleLogin({
          email: loginFields.email,
          password: loginFields.password
        });

        if (response?.status === 200 || response?.status === 201) {
          enqueueSnackbar("Login successful", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            }
          });

          setIsAuthenticated(true);
          setTimeout(() => {
            navigate('/');
          }, 500);
        } else {
          setError(true);
          enqueueSnackbar(response?.message || "Login failed", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            }
          });
        }

        setLoading(false);
      } catch (error) {
        console.log("Login error:", error);
        setLoading(false);
        setError(true);
        enqueueSnackbar("Login error", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          }
        });
      }
    } else {
      setValidationError("Please enter a valid email");
      enqueueSnackbar("Please enter a valid email", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        }
      });
    }
  };

  const BackgroundCircle = () => {
    return (
      <svg className="svg-background" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#3C50E0', stopOpacity: 0.8 }} />
            <stop offset="100%" style={{ stopColor: '#8e44ad', stopOpacity: 0.8 }} />
          </linearGradient>
          <linearGradient id="grad2" x1="0%" y1="100%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#3C50E0', stopOpacity: 0.8 }} />
            <stop offset="100%" style={{ stopColor: '#8e44ad', stopOpacity: 0.8 }} />
          </linearGradient>
        </defs>
        <circle cx="30" cy="30" r="14" fill="url(#grad1)" />
        <circle cx="70" cy="70" r="20" fill="url(#grad2)" />
      </svg>
    );
  };

  return (
    <div className="login-container">
      <BackgroundCircle />
      <SnackbarProvider />
      <div className="login-card">
        <h2>FQE Analytics</h2>
        {loading ? (
          <div style={{ margin: "100px 0" }}>
            <SyncLoader color={'#3C50E0'} loading={true} />
          </div>
        ) : (
          <Form
            type="login"
            handleSubmit={handleSubmit}
            validationError={validationError}
            formFields={{ email: loginFields.email, password: loginFields.password }}
            setFormFields={setLoginFields}
            switchForm={() => navigate("/signup")}
          />
        )}
      </div>
    </div>
  );
};

export default Login;