// src/authService.js

export const getToken = () => localStorage.getItem('access_token');

export const setToken = (token) => localStorage.setItem('access_token', token);

export const removeToken = () => localStorage.removeItem('access_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setRefreshToken = (token) => localStorage.setItem('refresh_token', token);

export const removeRefreshToken = () => localStorage.removeItem('refresh_token');