// import { Box, Card, CardHeader } from '@mui/material';
// import React from 'react';
// import ReactApexChart from 'react-apexcharts';
// import merge from 'lodash/merge';
// import { useTheme } from '@mui/material/styles';

// const MarketingGraph = ({ chartLabels, chartData, selectedFilter, selectedMetric }) => {

//   const filterDisplayNames = {
//     '7d': '7 Day',
//     '30d': '30 Day',
//     '90d': '90 Day'
//   };

//   const metricDisplayNames = {
//     spend: 'Spend',
//     impressions: 'Impressions',
//     inline_post_engagement: 'Post Engagement',
//     inline_link_clicks: 'Link Clicks'
//   };

//   function GraphInstance({ title, subheader, chartLabels, chartData, ...other }) {
//     const chartOptions = useChart({
//       labels: chartLabels,
//     });

//     return (
//       <Card {...other}>
//         <CardHeader title={`${metricDisplayNames[selectedMetric] || ''} YoY Comparison`} subheader={subheader} />
//         <Box sx={{ p: 3, pb: 1 }} dir="ltr">
//           <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
//         </Box>
//       </Card>
//     );
//   }

//   function useChart(options) {
//     const theme = useTheme();

//     const baseOptions = {
//       colors: [theme.palette.primary.main],
//       chart: {
//         toolbar: { show: true },
//         zoom: { enabled: true },
//         foreColor: theme.palette.text.disabled,
//         fontFamily: theme.typography.fontFamily,
//       },
//       markers: {
//         size: 0,
//         strokeColors: theme.palette.background.paper,
//       },
//       stroke: {
//         width: 3,
//         curve: 'smooth',
//         lineCap: 'round',
//       },
//       grid: {
//         strokeDashArray: 3,
//         borderColor: theme.palette.divider,
//         xaxis: {
//           lines: {
//             show: false,
//           },
//         },
//       },
//       tooltip: {
//         x: {
//           show: true,
//         },
//       },
//       legend: {
//         show: true,
//       },
//       xaxis: {
//         categories: options.labels.map(label => label.replace(/\/\d{4}$/, '')),
//         axisBorder: { show: true },
//         axisTicks: { show: true },
//         labels: {
//           rotateAlways: false,
//           rotate: 0,
//           style: {
//             fontSize: '10px',
//           },
//         },
//         tickAmount: selectedFilter === '90d' ? Math.floor(chartLabels.length / 7) : selectedFilter === '30d' ? Math.floor(chartLabels.length / 3) : chartLabels.length,
//       },
//       yaxis: {
//         labels: {
//           formatter: function (value) {
//             return value.toLocaleString();
//           }
//         }
//       }
//     };

//     return merge(baseOptions, options);
//   }

//   return (
//     <GraphInstance
//       chartLabels={chartLabels}
//       chartData={chartData}
//       selectedFilter={selectedFilter}
//       selectedMetric={selectedMetric}
//     />
//   );
// };

// export default MarketingGraph;

import { Box, Card, CardHeader, Stack } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import merge from 'lodash/merge';
import { useTheme } from '@mui/material/styles';

const MarketingGraph = ({ chartLabels, chartData, selectedFilter, selectedMetric }) => {
  const filterDisplayNames = {
    '7d': '7 Day',
    '30d': '30 Day',
    '90d': '90 Day',
  };

  const metricDisplayNames = {
    spend: 'Spend',
    impressions: 'Impressions',
    inline_post_engagement: 'Post Engagement',
    inline_link_clicks: 'Link Clicks',
  };

  // Chart instance for both the main chart and the brush chart
  function GraphInstance({ title, subheader, chartLabels, chartData, isBrush, ...other }) {
    const chartOptions = useChart({
      labels: chartLabels,
      isBrush,
    });

    return (
      <Card {...other}>
        <CardHeader title={title} subheader={subheader} />
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart
            type="line"
            series={chartData}
            options={chartOptions}
            height={isBrush ? 150 : 364} // Different heights for the brush and main charts
          />
        </Box>
      </Card>
    );
  }

  // Custom hook to manage chart options
  function useChart({ labels, isBrush }) {
    const theme = useTheme();

    const baseOptions = {
      colors: [theme.palette.primary.main],
      chart: {
        toolbar: { show: !isBrush }, // Hide toolbar in brush chart
        zoom: { enabled: !isBrush }, // Disable zoom in brush chart
        foreColor: theme.palette.text.disabled,
        fontFamily: theme.typography.fontFamily,
        brush: {
          enabled: isBrush, // Enable brushing in the lower chart
          target: 'main-chart', // Synchronize with the main chart
        },
        selection: {
          enabled: isBrush, // Enable selection in the brush chart
          fill: { color: theme.palette.primary.light, opacity: 0.3 },
        },
      },
      markers: {
        size: 0,
        strokeColors: theme.palette.background.paper,
      },
      stroke: {
        width: 3,
        curve: 'smooth',
        lineCap: 'round',
      },
      grid: {
        strokeDashArray: 3,
        borderColor: theme.palette.divider,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        x: {
          show: true,
        },
      },
      legend: {
        show: !isBrush, // Hide legend in brush chart
      },
      xaxis: {
        categories: labels.map(label => label.replace(/\/\d{4}$/, '')),
        axisBorder: { show: true },
        axisTicks: { show: true },
        labels: {
          rotateAlways: false,
          rotate: 0,
          style: {
            fontSize: '10px',
          },
        },
        tickAmount:
          selectedFilter === '90d'
            ? Math.floor(chartLabels.length / 7)
            : selectedFilter === '30d'
              ? Math.floor(chartLabels.length / 3)
              : chartLabels.length,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toLocaleString();
          },
        },
      },
    };

    return merge(baseOptions, isBrush ? { chart: { id: 'brush-chart' } } : { chart: { id: 'main-chart' } });
  }

  return (
    <Stack spacing={3}>
      {/* Main Graph */}
      <GraphInstance
        title={`${metricDisplayNames[selectedMetric] || ''} YoY Comparison`}
        subheader={`${filterDisplayNames[selectedFilter] || ''}`}
        chartLabels={chartLabels}
        chartData={chartData}
      />

      {/* Brush Graph */}
      <GraphInstance
        title={`Brush View - ${metricDisplayNames[selectedMetric] || ''}`}
        chartLabels={chartLabels}
        chartData={chartData}
        isBrush
      />
    </Stack>
  );
};

export default MarketingGraph;