import axios from 'axios';
import { getToken, setToken, removeToken, getRefreshToken } from './authServices';
import { API_URL } from './config/api-url';

const axiosInstance = axios.create({
    baseURL: `${API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// request interceptor to add token to headers
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getRefreshToken();

            if (refreshToken) {
                try {
                    const response = await axios.post(`${API_URL}/refresh/`, { token: refreshToken });
                    const newToken = response.data.access_token;

                    setToken(newToken);
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    removeToken(); 
                    window.location.href = '/login';
                    return Promise.reject(refreshError);
                }
            } else {
                removeToken();
                window.location.href = '/login'; 
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;