import React, { useMemo, useState, useEffect } from 'react';
import Sidebar from '../src/components/Sidebar';
import Topbar from '../src/components/Topbar';
import { Outlet } from 'react-router-dom';
import '../src/styles/App.css';
import '../src/styles/fonts.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketureAllMarkets } from './services/ticketure';

const Layout = () => {
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(true);

  const ticketureState = useSelector((state) => state.ticketure);
  const allMarkets = ticketureState.ticketureMarkets;
  const isMarketsLoading = ticketureState.ticketureMarketsLoading;
  const marketsError = ticketureState.error;
  const companyName = useSelector((state) => state.company.company);

  useEffect(() => {
    dispatch(fetchTicketureAllMarkets());
  }, [dispatch, companyName]);

  const markets = useMemo(() => {
    if (!allMarkets || isMarketsLoading) return [];
    return [...allMarkets].sort();
  }, [allMarkets, isMarketsLoading, companyName]);

  if (isMarketsLoading) {
    return <div>Loading markets...</div>;
  }

  if (marketsError) {
    return <div>Error loading markets: {marketsError}</div>;
  }

  return (
    <div className='layout-container'>
      {openSidebar && <Sidebar markets={markets} />}
      <Topbar markets={markets} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;