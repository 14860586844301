import React, { useState, useEffect, useMemo } from 'react';
import { useCompetitionContext } from '../context/CompetitionContext';
import '../styles/App.css';
import '../styles/fonts.css';
import { Button, Grid, TextField } from '@mui/material';
import ModalComp from './UI-components/Modal/modal';
import DataTable from './UI-components/Table/table';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GetCompetition } from '../middlewares/competition';
import { useAllEmployess } from '../middlewares/employess';

const Employees = () => {

    // Event
    const [editingEvent, setEditingEvent] = useState(null); // State to manage the editing event
    const [formFields, setFormFields] = useState({
        category: "",
        show: "",
        venue: "",
        city: "",
        state: "",
        opening_date: null,
        closing_date: null,
        prefs: "",
        notes: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [error, setError] = useState("");
    const [events, setEvents] = useState([])
    const { data, isLoading } = useAllEmployess()

    const handleOpen = () => {
        setShowModal(true)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleChange = (key, value) => {
        console.log(key, value);
        setFormFields((prevFields) => ({
            ...prevFields,
            [key]: value
        }));
    };

    const handleSubmit = () => {
        if (formFields.category === "" || formFields.show === "" || formFields.venue === "" || formFields.opening_date === null || formFields.closing_date === null || formFields.closing_date === null) {
            setError("Fields are required")
        }
        else {
            setEvents((prevEvents) => {
                const newId = prevEvents.length > 0 ? prevEvents[prevEvents.length - 1].id + 1 : 1;
                const obj = { ...formFields };
                const newEvent = Object.fromEntries(Object.entries(obj).filter(([k, v]) => v === "" || v === null ? false : true));
                return [...prevEvents, { ...newEvent, id: newId }];
            });
            setFormFields({
                category: "",
                show: "",
                venue: "",
                city: "",
                state: "",
                opening_date: null,
                closing_date: null,
                prefs: "",
                notes: "",
            })
            setShowModal(false)
        }
    }

    const handleUpdate = (updatedEvent) => {
        const index = events.findIndex((event) => event.id === updatedEvent.id);
        if (index !== -1) {
            const updatedEvents = [...events];
            updatedEvents[index] = updatedEvent;
            setEvents(updatedEvents);
            setShowEditModal(false)
        }
    };

    const handleUpdateSettings = () => {
        // Create an updated event object with form field values
        const updatedEvent = {
            ...editingEvent, // Keep other properties unchanged
            category: formFields.category,
            show: formFields.show,
            venue: formFields.venue,
            city: formFields.city,
            state: formFields.state,
            opening_date: formFields.opening_date,
            closing_date: formFields.closing_date,
            prefs: formFields.prefs,
            notes: formFields.notes,
        };
        // Call handleUpdate to update the event with the updatedEvent object
        handleUpdate(updatedEvent);
    };  

    console.log(data);

    return (
        <div>
            <div>
                {/* <div className='add-event-container'>
                    {showModal && (
                        <ModalComp handleClose={handleClose} open={showModal} modalTitle="Add New Event">
                            <Grid container spacing={2}>
                                {Object.entries(formFields).map(([k, v]) => {
                                    return (
                                        <>

                                            {k === "opening_date" ? (
                                                <Grid item xs={12} sm={12} md={6} key={k}>
                                                    <div className='my-date-picker'>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker onChange={(e) => handleChange(k, e.toISOString())}
                                                                    label={(<>{k.replace("_", " ")} <span style={{ color: "red" }}>*</span></>)}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                            ) : k === "closing_date" ? (
                                                <Grid item xs={12} sm={12} md={6} key={k}>
                                                    <div className='my-date-picker'>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker onChange={(e) => handleChange(k, e.toISOString())}
                                                                    label={(<>{k.replace("_", " ")} <span style={{ color: "red" }}>*</span></>)}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid >
                                            ) : (
                                                <Grid item xs={12} sm={12} md={6} key={k}>
                                                    <TextField
                                                        onChange={(e) => handleChange(k, e.target.value)}
                                                        value={v}
                                                        sx={{ margin: "10px 0", textTransform: "capitalize" }}
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label={(
                                                            <>
                                                                {k.replace("_", " ")}
                                                                <span style={{ color: "red" }}>
                                                                    {k === "city" || k === "state" || k === "prefs" || k === "notes" ? "" : "*"}
                                                                </span>
                                                            </>
                                                        )}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )
                                })}
                            </Grid>
                            {error && <p style={{ color: "red" }}>{error}</p>}
                            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                        </ModalComp>
                    )}
                </div> */}
                <DataTable
                    events={events}
                    handleUpdateSettings={handleUpdateSettings}
                    formFields={formFields}
                    handleChange={handleChange}
                    setEvents={setEvents}
                    setFormFields={setFormFields}
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    setEditingEvent={setEditingEvent}
                />
            </div>
        </div >
    );
};

export default Employees;