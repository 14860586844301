import React from 'react';
import { MapContainer, TileLayer, Circle, LayersControl, LayerGroup, Popup } from 'react-leaflet';
import '../styles/MapLegend.css';

const MarketMap = ({ center, eventSalesData, zipData }) => {
  const eventIds = eventSalesData.map(event => event.event_id);
  const customColors = ['#9B59B6', '#3498DB', '#1ABC9C', '#f58231', '#F1C40F'];
  const colorMap = eventIds.reduce((acc, eventId, index) => {
    acc[eventId] = customColors[index % customColors.length];
    return acc;
  }, {});

  return (
    <div>
      <MapContainer center={center} zoom={9} style={{ height: 600 }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayersControl position="topright">
          {eventSalesData.map(eventData => (
            <LayersControl.Overlay key={eventData.event_id} name={eventData.event_id} checked>
              <LayerGroup>
                {eventData.sales
                  .filter(sale => sale.zip_code && zipData[sale.zip_code])
                  .map(sale => {
                    const coords = zipData[sale.zip_code];
                    const radius = Math.sqrt(sale.tickets_purchased) * 500;
                    const color = colorMap[eventData.event_id];
                    return coords && !isNaN(radius) ? (
                      <Circle
                        key={`${eventData.event_id}-${sale.zip_code}`}
                        center={[coords.latitude, coords.longitude]}
                        radius={radius}
                        color={color}
                        fillColor={color}
                        fillOpacity={0.2}
                        opacity={0.8} // Adjust stroke opacity
                      >
                        <Popup>
                          <div>
                            <strong>Event ID:</strong> {eventData.event_id}<br />
                            <strong>Zip Code:</strong> {sale.zip_code}<br />
                            <strong>Tickets Purchased:</strong> {sale.tickets_purchased}
                          </div>
                        </Popup>
                      </Circle>
                    ) : null;
                  })}
              </LayerGroup>
            </LayersControl.Overlay>
          ))}
        </LayersControl>
      </MapContainer>
      <div className="legend">
        {eventSalesData.map(eventData => (
          <div key={eventData.event_id} className="legend-item">
            <span
              className="legend-color"
              style={{ backgroundColor: colorMap[eventData.event_id] }}
            ></span>
            {eventData.event_id}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketMap;