import axiosInstance from '../services';
import { useQuery } from 'react-query';

// generalized function for axios.get using axiosInstance
const fetchTicketureData = async (url) => {
  try {
    const companyName = localStorage.getItem('companyName') || 'jq';
    const response = await axiosInstance.get(`${companyName}${url}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// api endpoint calls
const fetchTicketureAllEventIDs = () => {
  const url = `/daily_ticket_sales/?event_ids=true`;
  return fetchTicketureData(url);
};

const fetchTicketureAllMarkets = () => {
  const url = `/daily_ticket_sales/?market_names=true`;
  return fetchTicketureData(url);
};

const fetchTicketureByMarket = (market) => {
  const url = `/daily_ticket_sales/?market_name=${market}`;
  return fetchTicketureData(url);
};

const fetchTicketureByEventID = (eventID) => {
  const url = `/daily_ticket_sales/?event_id=${eventID}`;
  return fetchTicketureData(url);
};

const fetchTicketureByOpenDate = (startDate, endDate) => {
  const url = `/daily_ticket_sales/?generalized_start_date=${startDate}&generalized_end_date=${endDate}`;
  return fetchTicketureData(url);
};

const fetchTicketureByAttendingDate = (startDate, endDate) => {
  const url = `/daily_ticket_sales/?start_date=${startDate}&end_date=${endDate}`;
  return fetchTicketureData(url);
};

const fetchTicketureZipCodeByEventID = (eventID) => {
  const url = `/zip_code_sales/?event_id=${eventID}`;
  return fetchTicketureData(url);
};

const fetchPageViews = (startDate, endDate) => {
  const url = `/page_views/?start_date=${startDate}&end_date=${endDate}`;
  return fetchTicketureData(url);
};

// hooks
export const usePageViewsByDate = (startDate, endDate) => {
  return useQuery(
    ['pageViews', startDate, endDate],
    () => fetchPageViews(startDate, endDate),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,  
      staleTime: Infinity         
    }
  );
};

export const useTicketureAllEventIDs = () => {
  return useQuery('ticketureAllEventIDs', fetchTicketureAllEventIDs, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureAllMarkets = () => {
  return useQuery('ticketureAllMarketNames', fetchTicketureAllMarkets, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureByMarket = (market) => {
  return useQuery(['ticketureByMarket', market], () => fetchTicketureByMarket(market), {
    enabled: !!market,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureByEventID = (eventIDs) => {
  return useQuery(['ticketureByEventIds', eventIDs], () => Promise.all(eventIDs.map(fetchTicketureByEventID)), {
    enabled: eventIDs.length > 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureByOpenDate = (startDate, endDate) => {
  return useQuery(['ticketureByOpenDate', startDate, endDate], () => fetchTicketureByOpenDate(startDate, endDate), {
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureByAttendingDate = (startDate, endDate) => {
  return useQuery(['ticketureByAttendingDate', startDate, endDate], () => fetchTicketureByAttendingDate(startDate, endDate), {
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTicketureZipCodeByEventID = (eventIDs) => {
  return useQuery(['salesDataByEventIds', eventIDs], () => Promise.all(eventIDs.map(fetchTicketureZipCodeByEventID)), {
    enabled: eventIDs.length > 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};