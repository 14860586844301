import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Paper, TextField, Button, Autocomplete } from '@mui/material';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import SkeletonLoader from './SkeletonLoader';
import { useTicketureAllEventIDs, useTicketureByOpenDate } from '../middlewares/ticketure';
import { fetchMetaInsigthByEventIDs, fetchTicketureByEventIDs } from '../services/ticketure';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/drilldownSlice';
import dayjs from 'dayjs';

const Drilldown = () => {
  const apiRef = useGridApiRef();
  const columns = [
    { headerAlign: 'center', headerName: "Week Number", field: "week_number" },
    { headerAlign: 'center', headerName: "Day of Week", field: "day_of_week" },
    { headerAlign: 'center', headerName: "Sale Date", field: "sale_date" },
    { headerAlign: 'center', headerName: "Event ID", field: "event_id" },
    { headerAlign: 'center', headerName: "Market Name", field: "market_name" },
    { headerAlign: 'center', headerName: "Median Income", field: "median_income", align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "Digital Audience Size", field: "digital_audience_size", align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Days Out", field: "days_out", align: 'center',  },
    { headerAlign: 'center', headerName: "Marketing Phase", field: "marketing_phase", align: 'center',  },
    { headerAlign: 'center', headerName: "Daily Ticket Count", field: "daily_ticket_count",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Daily Revenue", field: "revenue",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "Total Tickets", field: "cumulative_ticket_count",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Total Revenue", field: "total_revenue",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "Avg Tickets", field: "running_avg_daily_tickets",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "Spend", field: "spend",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "Total Spend", field: "cumulative_spend",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: "ROAS", field: "roas",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = Math.round(value)
        return `${num.toLocaleString()} %`
      }}
    },
    { headerAlign: 'center', headerName: "Impressions", field: "impressions",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Clicks", field: "clicks",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Unique Clicks", field: "unique_clicks",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: 'Engagements', field: "inline_post_engagement",  align: 'center', valueGetter: (value) => value.toLocaleString() },
    { headerAlign: 'center', headerName: "Frequency", field: "frequency",  align: 'center', valueGetter: (value) => { 
      if (value) {
        let num = value.toFixed(2)
        return num.toLocaleString()
      }}
    },
    { headerAlign: 'center', headerName: 'CPT', field: 'cpt', align: 'center',  },
    { headerAlign: 'center', headerName: "CTR", field: "ctr", align: 'center',  },
    { headerAlign: 'center', headerName: "CPM", field: "cpm", align: 'center',  },
    { headerAlign: 'center', headerName: "CPE", field: "cpe", align: 'center',  },
  ];

  const [selectedEventIDs, setSelectedEventIDs] = useState([]);
  const [appliedEventIDs, setAppliedEventIDs] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [error, setError] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    week_number: false,
    day_of_week: false,
    median_income: false,
    digital_audience_size: false, 
  });

  const { data: allEventIDs } = useTicketureAllEventIDs();
  const { data: staticData } = useQuery("forecastData", async () => {
    const response = await axios.get("https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/forecast-data");
    return response.data;
  });

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.drilldown.loading);

  // Date calculations
  const today = dayjs();
  const startDate = today.format('YYYY-MM-DD');
  const endDate = today.add(2, 'week').format('YYYY-MM-DD');

  // Fetch events within the date range
  const { data: ticketureData, error: ticketureError, isLoading: ticketureLoading } = useTicketureByOpenDate(startDate, endDate);

  // Utility function to ensure uniqueness
  const getUniqueDataByMaxCumulativeTicketCount = (data) => {
    const map = new Map();
    data.forEach((item) => {
      const key = `${item.event_id}-${item.sale_date}`;
      if (
        !map.has(key) ||
        map.get(key).cumulative_ticket_count < item.cumulative_ticket_count
      ) {
        map.set(key, item);
      }
    });
    return Array.from(map.values());
  };

  // Helper function to normalize strings
  const normalizeString = (str) => str.trim().toLowerCase();

  // Set default event IDs based on events within the date range
  useEffect(() => {
    if (ticketureData && !ticketureLoading) {
      const eventIDs = Array.from(new Set(ticketureData.map(event => event.event_id)));
      setSelectedEventIDs(eventIDs);
      setAppliedEventIDs(eventIDs);
    }
    if (ticketureError) {
      setError(ticketureError);
    }
  }, [ticketureData, ticketureLoading, ticketureError]);

  useEffect(() => {
    if (appliedEventIDs.length > 0) {
      const fetchAllEventData = async () => {
        setError(null);
        try {
          dispatch(setLoading({ isLoading: true, type: "dailyTicketSalesMetaInsight" }));
          const allEventData1 = await Promise.all(
            appliedEventIDs.map((eventID) => dispatch(fetchTicketureByEventIDs(eventID)))
          );
          const allEventData2 = await Promise.all(
            appliedEventIDs.map((eventID) => dispatch(fetchMetaInsigthByEventIDs(eventID)))
          );
          const rawData1 = allEventData1.flatMap((response) => response.payload);
          const rawData2 = allEventData2.flatMap((response) => response.payload);
  
          const filterArr = rawData1
            .map((elem) => {
              const matchIndex = rawData2.findIndex(
                (elem2) =>
                  elem.event_id === elem2.event_id && elem.sale_date === elem2.date_start
              );
              if (matchIndex !== -1) {
                const combinedItem = { ...elem, ...rawData2[matchIndex] };
  
                // Calculate marketing_phase
                const daysOut = combinedItem.days_out;
                let marketing_phase = null;
  
                if (daysOut >= 121) {
                  marketing_phase = 1;
                } else if (daysOut <= 120 && daysOut >= 31) {
                  marketing_phase = 2;
                } else if (daysOut <= 30 && daysOut >= 15) {
                  marketing_phase = 3;
                } else if (daysOut <= 14 && daysOut >= 8) {
                  marketing_phase = 4;
                } else if (daysOut <= 7 && daysOut >= -5) {
                  marketing_phase = 5;
                }
  
                combinedItem.marketing_phase = marketing_phase;
  
                // Calculate week_number and day_of_week
                const saleDate = dayjs(combinedItem.sale_date);
                combinedItem.week_number = saleDate.week();
                combinedItem.day_of_week = saleDate.format('dddd');
  
                return combinedItem;
              }
              return null;
            })
            .filter(Boolean);
  
          // Remove duplicates, keep item with largest cumulative_ticket_count
          const uniqueFilterArr = getUniqueDataByMaxCumulativeTicketCount(filterArr);
  
          // Sort the data by event_id and sale_date ascending
          uniqueFilterArr.sort((a, b) => {
            if (a.event_id === b.event_id) {
              return dayjs(a.sale_date).valueOf() - dayjs(b.sale_date).valueOf();
            } else {
              return a.event_id.localeCompare(b.event_id);
            }
          });
  
          // Initialize cumulative variables per event_id
          const cumulativeRevenuePerEvent = {};
          const cumulativeSpendPerEvent = {};
          const firstSaleDatePerEvent = {};
          const staticDataMap = {};
          staticData.forEach((item) => {
            const clusterMarket = item["Cluster Market"];
            if (clusterMarket) {
              const normalizedClusterMarket = normalizeString(clusterMarket);
              staticDataMap[normalizedClusterMarket] = item;
            }
          });
  
          // Iterate over the sorted data to calculate cumulative values per event_id
          const dataWithCalculations = uniqueFilterArr.map((item) => {
            const eventId = item.event_id;
  
            // Initialize cumulative revenue and spend for the event_id if not already done
            if (!cumulativeRevenuePerEvent[eventId]) {
              cumulativeRevenuePerEvent[eventId] = 0;
            }
            if (!cumulativeSpendPerEvent[eventId]) {
              cumulativeSpendPerEvent[eventId] = 0;
            }
  
            // Set the first sale date per event_id
            if (!firstSaleDatePerEvent[eventId]) {
              firstSaleDatePerEvent[eventId] = dayjs(item.sale_date);
            }
  
            // Accumulate revenue and spend
            cumulativeRevenuePerEvent[eventId] += Number(item.revenue) || 0;
            cumulativeSpendPerEvent[eventId] += Number(item.spend) || 0;
  
            // Calculate days since first sale (inclusive)
            const daysSinceFirstSale =
              dayjs(item.sale_date).diff(firstSaleDatePerEvent[eventId], 'day') + 1;
  
            // Calculate running average using cumulative_ticket_count
            const runningAverageTicketsSold = parseFloat(
              (Number(item.cumulative_ticket_count) / daysSinceFirstSale).toFixed(2)
            );
  
            // Calculate Cost Per Ticket (CPT) using cumulative values
            let cpt = null;
            const cumulativeTicketsSold = Number(item.cumulative_ticket_count) || 0;
            const cumulativeSpend = cumulativeSpendPerEvent[eventId];
  
            if (cumulativeTicketsSold > 0) {
              cpt = cumulativeSpend / cumulativeTicketsSold;
            } else if (cumulativeTicketsSold === 0 && cumulativeSpend > 0) {
              cpt = Infinity;
            } else {
              cpt = 0;
            }
  
            // Format CPT to fixed decimal places
            cpt = parseFloat(cpt.toFixed(2));

            // Calculate ROAS
            const roas = cumulativeRevenuePerEvent[eventId] / cumulativeSpendPerEvent[eventId]

            // Map static data based on market_name matching "Cluster Market"
            const marketName = item.market_name;
            const normalizedMarketName = normalizeString(marketName);
            const staticItem = staticDataMap[normalizedMarketName];

            // Initialize variables to hold the static data values
            let medianIncome = null;
            let digitalAudienceSize = null;

            if (staticItem) {
              medianIncome = staticItem["Median Income ($)"];
              digitalAudienceSize = staticItem["Digital Audience Size (20 Mile)"];
            } else {
              console.warn(`No static data found for market: ${marketName}`);
            }
  
            return {
              ...item,
              total_revenue: cumulativeRevenuePerEvent[eventId],
              cumulative_spend: cumulativeSpend.toFixed(2), 
              running_avg_daily_tickets: runningAverageTicketsSold,
              roas: roas*100,
              cpt,
              median_income: medianIncome,
              digital_audience_size: digitalAudienceSize,
            };
          });
  
          setEventData(dataWithCalculations);
          dispatch(setLoading({ isLoading: false, type: "" }))
          await new Promise(r => setTimeout(r, 0))
          .then(() =>
          apiRef.current.autosizeColumns({
            includeHeaders: true, 
            includeOutliers: true,
            outliersFactor: 4
          }))
        } catch (error) {
          setError(error);
          dispatch(setLoading({ isLoading: false, type: "" }));
        }
      };
      fetchAllEventData();
    }
  }, [dispatch, appliedEventIDs, apiRef]);
  
  const handleApply = () => {
    setAppliedEventIDs([...selectedEventIDs]);
  };

  const handleExportCSV = () => {
    const csvRows = [];
    const headers = [
      'week_number',
      'day_of_week',
      'sale_date',
      'event_id',
      'market_name',
      'median_income',
      'digital_audience_size',
      'days_out',
      'marketing_phase',
      'daily_ticket_count',
      'revenue',
      'total_revenue',
      'cumulative_spend',
      'roas',
      'running_avg_daily_tickets',
      'cost_per_ticket',
      'cumulative_ticket_count',
      'spend',
      'impressions',
      'clicks',
      'unique_clicks',
      'inline_post_engagement',
      'frequency',
      'cpt',
      'ctr',
      'cpm',
      'cpe',
    ];
    csvRows.push(headers.join(','));
  
    eventData.forEach((row) => {
      const values = [
        row.week_number,
        row.day_of_week,
        row.sale_date,
        row.event_id,
        row.market_name,
        row.median_income,
        row.digital_audience_size,
        row.days_out,
        row.marketing_phase,
        row.daily_ticket_count,
        row.revenue,
        row.total_revenue,
        row.cumulative_spend,
        row.roas,
        row.running_avg_daily_tickets,
        row.cpt,
        row.cumulative_ticket_count,
        row.spend,
        row.impressions,
        row.clicks,
        row.unique_clicks,
        row.inline_post_engagement,
        row.frequency,
        row.cpt,
        row.ctr,
        row.cpm,
        row.cpe,
      ];
  
      // Map over values to handle commas and quotes
      const formattedValues = values.map((value) => {
        if (value === null || value === undefined) {
          return '';
        }
        const stringValue = value.toString();
  
        // Check if value contains a comma, double-quote, or newline
        if (/[",\n]/.test(stringValue)) {
          // Escape double quotes by replacing " with ""
          const escapedValue = stringValue.replace(/"/g, '""');
          // Enclose the value in double quotes
          return `"${escapedValue}"`;
        }
        return stringValue;
      });
  
      csvRows.push(formattedValues.join(','));
    });
  
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'drilldown.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  

  if (error) return <div>Error: {error.message}</div>;

  return (
    <Paper style={{ padding: '30px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            multiple
            options={allEventIDs || []}
            getOptionLabel={(option) => option}
            value={selectedEventIDs}
            onChange={(event, newValue) => setSelectedEventIDs(newValue)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select Event IDs" />
            )}
            style={{ width: '1000px', marginRight: '20px' }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100px', fontWeight: '900', marginRight: '20px', maxHeight: '50px' }}
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button 
            variant="contained" 
            onClick={handleExportCSV}
            style={{ fontWeight: '900', marginRight: '20px', maxHeight: '50px' }}
          >
            Export CSV
          </Button>
        </div>
      </div>
      {loading.isLoading && loading.type === 'dailyTicketSalesMetaInsight' ? (
        <SkeletonLoader loading={loading.isLoading} width="100%" height={450} />
      ) : (
        //<DataTable columns={columns} data={eventData} />
        <div style={{ height: "70vh", width: '100%', overflowX: "auto", position: "relative" }}>
          <DataGrid
            apiRef={apiRef} 
            columns={columns} 
            rows={eventData} 
            pageSize={5}
            slots={{ toolbar: GridToolbar }} 
            style={{ width: "100%", minWidth: '600px', position:"absolute" }}
            showCellVerticalBorder
            showColumnVerticalBorder
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </div>
      )}
    </Paper>
  );
};

export default Drilldown;
