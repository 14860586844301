import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { GetUserProfile } from '../middlewares/auth';
import jwtDecoder from '../config/jwt-decoder';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [token, setToken] = useState(localStorage.getItem("access_token"));
  const [refCodeFields, setRefCodeFields] = useState({ code: "" });
  const [loginFields, setLoginFields] = useState({ email: "", password: "" });
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    is_active: true,
    reference_code: { code: "" }
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [validationError, setValidationError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // handle logout and token removal
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setToken(null);
    setIsAuthenticated(false);
    setFormFields({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_active: true,
        reference_code: { code: "" }
    });
    setLoginFields({ email: "", password: "" });
    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };

  // fetch user details using the token
  const getUserDetails = useCallback(async () => {
    if (!token) return;

    try {
      const decoded = jwtDecoder(token);
      const id = decoded.sub;
      setLoading(true);
      console.log("Fetching user details...");

      const response = await GetUserProfile(id);

      if (response.status === 200) {
        setFormFields(prevFormFields => ({
          ...prevFormFields,
          first_name: response.data.first_name || "",
          last_name: response.data.last_name || "",
          email: response.data.email || "",
          password: "",
          is_active: response.data.is_active,
          reference_code: { code: response.data.reference_code?.code || "" }
        }));

        setUser(response.data);
        setIsAuthenticated(true);
      } else {
        console.log("Failed to fetch user details. Status code:", response.status);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching user details:", error);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecoder(token);
        console.log("Token decoded successfully:", decoded);
        getUserDetails().then(() => {
          setAuthLoading(false);
          console.log("Auth loading set to false");
        });
      } catch (error) {
        console.error("Token decoding error:", error);
        handleLogout();
      }
    } else {
      setIsAuthenticated(false);
      setAuthLoading(false);
      setFormFields({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_active: true,
        reference_code: { code: "" }
      });
      setLoginFields({ email: "", password: "" });
      console.log("No token found, setting authLoading to false");
    }
  }, [token, getUserDetails]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      handleLogout,
      user,
      setUser,
      loading,
      setLoading,
      loading2,
      setLoading2,
      loading3,
      setLoading3,
      formFields,
      setFormFields,
      loginFields,
      setLoginFields,
      refCodeFields,
      setRefCodeFields,
      token,
      setToken,
      success,
      setSuccess,
      error,
      setError,
      validationError,
      setValidationError,
      refCode,
      setRefCode,
      emailRegex,
      authLoading, 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);